import React, { useContext, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import calculateSummaryParams from "utils/calculate_summary_params";

import BookingSummary from 'components/booking_summary';
import Footer from 'components/footer';
import Header from 'components/header';
import SectionWrapper from 'components/layout/section_wrapper';
import Loading from 'components/loading';
import Navigation from 'components/navigation';
import BookingCheckoutForm from 'components/booking_checkout_form';

import { AppActionsContext, BookingActionsContext, BookingDataContext } from 'containers/data_context';

import routes from 'routing/routes';

import buildPath from 'utils/build_path';
import isCarProperty from 'utils/is_car_property';

export default function BookingCheckoutPage() {
  const { property, params, propertyUnitsInfo, channelId } = useContext(BookingDataContext);
  const bookingActions = useContext(BookingActionsContext);
  const { init } = useContext(AppActionsContext);
  const history = useHistory();
  const { data: propertyData, isLoading: isPropertyLoading } = property;

  const { data: unitsData, isLoading: isUnitsLoading } = propertyUnitsInfo;

  const hasProperty = propertyData && !isPropertyLoading;
  const hasUnits = unitsData && !isUnitsLoading;
  const { ratesOccupancyPerUnit, checkinDate, checkoutDate } = params;
  const isReqiredDataPresent = ratesOccupancyPerUnit && checkinDate && checkoutDate;

  const [total, setTotal] = useState(0);
  const [selectedRatesPerUnit, setSelectedRatesByUnit] = useState({});

  const onSuccess = bookingParams => {
    const { uniqueId, bookingId, bookingUniqueId } = bookingParams;
    const routeParams = { channelId, bookingId: uniqueId || bookingUniqueId || bookingId};
    const { search } = history.location;
    const confirmationPagePath = buildPath(search, routes.confirmationPage, routeParams);

    history.push(confirmationPagePath);
  };

  useEffect(() => {
    return () => {
      bookingActions.clearPropertyUnitsInfo();
    }
  }, [])

  useEffect(
    function initApp() {
      const savedBookingParams = bookingActions.getDataFromStorage();

      init(bookingActions, savedBookingParams);
    },
    [bookingActions, init],
  );

  useEffect(
    function setSummaryParams() {
      if (isCarProperty(params.type)) {
        let numOfDays;
        let numOfEntireDays = dayjs(params.checkoutDate).diff(dayjs(params.checkinDate), 'day');
        if (params.pickupTime < params.dropoffTime) {
          numOfDays = numOfEntireDays + 1;
        } else {
          numOfDays = numOfEntireDays;
        }

        const pricePerDay = unitsData?.[0]?.ratePlans?.[0]?.totalPrice / numOfEntireDays;
        const total = pricePerDay * numOfDays;
        setTotal(pricePerDay * numOfDays);

        const selectedRatesByUnit = {
          [unitsData?.[0]?.id]: {
            total,
            selectedRates: [{
              ...unitsData?.[0]?.ratePlans,
              netPrice: total,
              total,
              lengthOfStay: numOfDays,
              amount: 1,
            }],
          }
        }

        setSelectedRatesByUnit(selectedRatesByUnit);
      } else {
        const summaryParams = calculateSummaryParams(unitsData, ratesOccupancyPerUnit, params);

        if (!summaryParams) {
          return;
        }

        setTotal(summaryParams.total);
        setSelectedRatesByUnit(summaryParams.selectedRatesPerUnit);
      }
    },
    [unitsData, ratesOccupancyPerUnit],
  );

  if (!hasProperty || !hasUnits) {
    return <Loading />;
  }

  if (!isReqiredDataPresent) {
    const redirectPath = buildPath('', routes.propertyPage, { channelId });

    return <Redirect to={redirectPath} />;
  }

  return (
    <div>
      <Header property={propertyData} />
      <SectionWrapper theme="dark">
        <Col xs="12" lg="8">
          <>
            <Navigation />
            <BookingCheckoutForm
              property={propertyData}
              params={params}
              channelId={channelId}
              onSuccess={onSuccess}
              units={unitsData}
              total={total}
            />
          </>
        </Col>
        <Col xs="12" lg="4">
          <BookingSummary property={propertyData} params={params} total={total} selectedRatesPerUnit={selectedRatesPerUnit} />
        </Col>
      </SectionWrapper>
      <Footer property={propertyData} />
    </div>
  );
}
