import dayjs from "dayjs";

import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "containers/data_context/utils/session_storage_manager";

import { giftCardBookingParams } from "constants/storage_keys";

export function getSavedState() {
  const savedState = getFromStorage(giftCardBookingParams);

  if (!savedState) {
    return null;
  }
  
  const { params = {}, ...rest } = savedState;

  if (params.checkinDate && params.checkoutDate) {
    const updatedParams = {
      ...params,
      checkinDate: dayjs(params.checkinDate),
      checkoutDate: dayjs(params.checkoutDate),
    };
    return { ...rest, params: updatedParams };
  } else {
    return savedState;
  }
}

export function setSavedState(state) {
  setToStorage(giftCardBookingParams, state);
}

export function clearSavedState() {
  removeFromStorage(giftCardBookingParams);
}
