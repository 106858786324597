// Generated with util/create-component.js
import React, { useContext, useEffect, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsType } from 'antd/lib/table';
import { TablePaginationConfig } from 'antd/es/table';
import { Table, Dropdown, Popconfirm, Tooltip, Drawer, Row, Col, Button, Checkbox, MenuProps } from 'antd';
import { DownOutlined, EditOutlined, UserDeleteOutlined, SettingOutlined, PlusCircleOutlined } from '@ant-design/icons';

import notification from 'utils/notification';
import DRAWER_SIZE from 'constants/drawer_size';

import {
  AdminUsersActionsContext,
  AdminUsersDataContext,
  AdminResellersDataContext,
  AdminResellersActionsContext,
  AdminPropertiesDataContext,
  AdminPropertiesActionsContext,
  AuthenticationDataContext,
} from 'containers/data_context';

import UserForm from 'components/users/user_form/user_form';
import SearchInput from 'components/search_input';
import ChangeUserPasswordForm from 'components/users/change_user_password_form/change_user_password_form';

import { IProperty } from 'components/admin/properties/properties_list/properties_list.types';
import { IPasswordData } from 'components/users/change_user_password_form/change_user_password_form.types';

import AddUserForm from '../add_user_form/add_user_form';

import { IUser, IUsersListFilters } from './users_list.types';
import styles from './users_list.module.scss';

const UsersList: React.FC = () => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<IUsersListFilters>({
    searchTerm: '',
    isActive: true,
    pagination: {
      page: 1,
      perPage: 50,
    },
  });

  const [editUser, setEditUser] = useState<IUser | null>(null);
  const [openedAddUserForm, toggleAddUserForm] = useState(false);
  const [changePasswordUser, setChangePasswordUser] = useState<IUser | null>(null);
  const [openedChangePasswordForm, toggleChangePasswordForm] = useState(false);

  const { loadUsers, updateUserStatus, updateUser } = useContext(AdminUsersActionsContext);

  const { loadResellers } = useContext(AdminResellersActionsContext);
  const { loadProperties } = useContext(AdminPropertiesActionsContext);

  const {
    resellers: { data: resellers, isLoading: isLoadingResellers },
  } = useContext(AdminResellersDataContext);

  const {
    properties: { data: properties },
  } = useContext(AdminPropertiesDataContext);

  const {
    users: { data, isLoading },
    user: { isUpdating },
  } = useContext(AdminUsersDataContext);

  const { user } = useContext(AuthenticationDataContext);

  useEffect(() => {
    const formatedFilters = {
      pagination: filters.pagination,
      ...(filters.isActive && { isActive: filters.isActive }),
      ...(filters.searchTerm && { q: filters.searchTerm }),
    };
    loadUsers(formatedFilters);
  }, [loadUsers, filters]);

  const renderSuppliersNames = (suppliers: IProperty[]) => {
    const suppliersNames = suppliers.reduce((names: Array<IProperty['name']>, hotel: IProperty) => {
      names.push(hotel.name);
      return names;
    }, []);

    return suppliersNames.join(', ');
  };

  const handleCloseEditForm = () => {
    setEditUser(null);
  };

  const handleOpenEditUserForm = (user: IUser) => {
    if (!properties?.reservaHotels?.length) {
      loadProperties();
    }
    if (!resellers?.length) {
      loadResellers();
    }
    setEditUser(user);
  };

  const handleToggleAddUserForm = () => {
    toggleAddUserForm(!openedAddUserForm);
  };

  const handleCloseChangePassword = () => {
    setChangePasswordUser(null);
    toggleChangePasswordForm(false);
  };

  const handleOpenChangePassword = (user: IUser) => {
    setChangePasswordUser(user);
    toggleChangePasswordForm(true);
  };

  const handleUserStatus = (user: IUser) => {
    const usersData = {
      id: user.id,
      status: user.status === 'active' ? 'blocked' : 'active',
    };

    updateUserStatus(usersData);
  };

  const handleUpdatePassword = async (passwordData: IPasswordData) => {
    await updateUser({ ...changePasswordUser, ...passwordData });
  };

  const renderMenu = (user: IUser): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'edit',
        label: t('general.edit'),
        icon: <EditOutlined />,
        onClick: () => handleOpenEditUserForm(user),
      },
      {
        key: user.status === 'active' ? 'Block' : 'Unblock',
        label: (
          <Tooltip title={user.status === 'active' ? t('user.block_user') : t('user.unblock_user')}>
            <Popconfirm
              title={
                <>
                  <p>{t('bookings_list.confirm')}</p>
                  {user.status === 'active' && <p>{`${t('user.block_confirmation')} ${user.name}`}?</p>}
                  {user.status === 'blocked' && <p>{`${t('user.unblock_confirmation')} ${user.name}`}?</p>}
                </>
              }
              cancelText={t('link.cancel')}
              okText={t('link.yes')}
              placement="bottomRight"
              onConfirm={() => handleUserStatus(user)}
            >
              {user.status === 'active' ? t('user.block') : t('user.unblock')}
            </Popconfirm>
          </Tooltip>
        ),
        icon: <UserDeleteOutlined />,
      },
      {
        key: 'changePassword',
        label: t('user.change_password'),
        icon: <SettingOutlined />,
        onClick: () => handleOpenChangePassword(user),
      },
    ];

    return { items: menuItems };
  };

  const renderActionButtons = (user: IUser) => {
    return (
      <Dropdown menu={renderMenu(user)}>
        <div className={styles.actions_link}>
          <span className={styles.action_link_text}>{t('general.actions')}</span> <DownOutlined />
        </div>
      </Dropdown>
    );
  };

  const renderResellerName = (reseller: IUser['agent']) => reseller?.name || '';

  const usersListColumns: ColumnsType<IUser> = useMemo(
    () => [
      {
        title: t('general.other.email'),
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: t('general.other.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('bookings_list.status_title'),
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: t('general.other.properties'),
        render: (record: IUser) => renderSuppliersNames(record.reservaHotels),
      },
      {
        title: t('general.reseller'),
        key: 'agent',
        render: (record: IUser) => renderResellerName(record.agent),
      },
      {
        key: 'actions',
        render: (record: IUser) => renderActionButtons(record),
      },
    ],
    [],
  );

  const handlePageChange = useCallback(
    async (currentPagination: TablePaginationConfig) => {
      const { current, pageSize } = currentPagination;
      const pagination = {
        page: current || 1,
        perPage: pageSize || 50,
      };

      setFilters({ ...filters, pagination });
    },
    [filters],
  );

  const handleSearchTermChange = useCallback(
    async (term: string) => {
      setFilters({
        ...filters,
        pagination: {
          page: 1,
          perPage: 50,
        },
        searchTerm: term,
      });
    },
    [filters],
  );

  const handleToggleIsActive = (isActive: boolean) => {
    setFilters({
      ...filters,
      isActive,
      pagination: {
        page: 1,
        perPage: 50,
      },
    });
  };

  const handleUpdateUser = async (user: IUser): Promise<void> => {
    if (!user.agentId) {
      user.agentId = null;
    }

    try {
      await updateUser(user);
      notification.withIcon('success', t('general.success_message'));
    } catch (e) {
      console.log(e, 'error');
      notification.withIcon('error', t('general.error_message'), t('general.error_description'));
    }
  };

  return (
    <div data-testid="UsersList" className={styles.root}>
      <Row justify="space-between" align="middle" className={styles.header_container} gutter={[16, 16]}>
        <Col lg={17} sm={24} xs={24}>
          <SearchInput onSearch={handleSearchTermChange} placeholder={t('general.search_user')} />
        </Col>
        <Col lg={3} offset={1} sm={24} xs={11}>
          <Checkbox
            className={styles.select_all}
            onChange={e => handleToggleIsActive(e.target.checked)}
            checked={filters.isActive}
          >
            {t('general.only_active')}
          </Checkbox>
        </Col>
        <Col lg={3} sm={24} xs={12}>
          <Button
            onClick={handleToggleAddUserForm}
            type="primary"
            size="large"
            icon={<PlusCircleOutlined />}
            className={styles.create_button}
          >
            {t('general.add_user')}
          </Button>
        </Col>
      </Row>
      <Table
        loading={isLoading}
        scroll={{ y: window.innerHeight - 300 }}
        columns={usersListColumns}
        dataSource={data?.users}
        pagination={{
          current: filters.pagination?.page,
          total: data?.total,
          pageSize: filters.pagination?.perPage,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onChange={handlePageChange}
        rowKey="id"
      />

      {editUser && (
        <Drawer
          open={!!editUser}
          title={t('general.buttons.edit')}
          width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
          onClose={handleCloseEditForm}
          destroyOnClose={true}
        >
          <UserForm
            user={editUser}
            onCancel={handleCloseEditForm}
            isSaving={isUpdating}
            onSaveUser={handleUpdateUser}
            properties={properties?.reservaHotels}
            resellers={resellers?.agents}
            isLoadingResellers={isLoadingResellers}
            isChangeable={user?.id !== editUser?.id || editUser?.role === 'admin'}
          />
        </Drawer>
      )}

      <Drawer
        open={openedAddUserForm}
        title={t('general.add_user')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
        onClose={handleToggleAddUserForm}
        destroyOnClose={true}
      >
        <AddUserForm onCancel={handleToggleAddUserForm} />
      </Drawer>

      <Drawer
        open={openedChangePasswordForm}
        title={t('user.change_password')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
        onClose={handleCloseChangePassword}
        destroyOnClose={true}
      >
        <ChangeUserPasswordForm
          isSaving={isUpdating}
          onCancel={handleCloseChangePassword}
          onSave={handleUpdatePassword}
        />
      </Drawer>
    </div>
  );
};

export default UsersList;
