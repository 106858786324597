import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import countries from 'constants/countries';
import Dropdown from 'components/dropdown';
import CurrencySelectControlled from 'components/inputs/currency_select_controlled';
import OccupancySettingsForm from 'components/search_section/occupancy_settings/occupancy_settings_form';
import DateRangePicker from 'components/date_range_picker/date_range_picker';
import PlaceSettings from 'components/place_settings/place_settings';

import styles from './header_search.module.scss';
import { IHeaderSearchProps } from './header_search.types';
import isAccommodationProperty from 'utils/is_accommodation_property';
import isCarProperty from 'utils/is_car_property';

const HeaderSearch: React.FC<IHeaderSearchProps> = ({
  searchParams,
  handleDatesChange,
  handleChangeOccupancy,
  handleCurrencyChange,
  handlePlaceChange,
  isLoadingProperties,
  propertiesData,
}) => {
  const isCar = useMemo(() => isCarProperty(searchParams.type), [searchParams.type]);

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const getDropdownTitle = () => {
    const title = `${searchParams.adults} ${t('hotel_page.adults')} ·
    ${searchParams.children} ${t('hotel_page.children')}`;

    return title;
  };

  const getPlaceSearchTerm = useCallback(() => {
    if (searchParams?.country) {
      const countryName = countries.find(c => c.value === searchParams.country)?.label;
      return countryName || '';
    }

    return searchParams.state || searchParams.city || searchParams.title || null;
  }, [searchParams]);

  const dropdownTitle = getDropdownTitle();
  const placeSearchTerm = getPlaceSearchTerm();

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <p className={styles.title}>{t('dashboard.search_placeholder')}</p>

        <PlaceSettings
          onSelectOption={handlePlaceChange}
          propertiesData={propertiesData}
          isLoading={isLoadingProperties}
          variant="borderless"
          searchTerm={placeSearchTerm}
        />
        <DateRangePicker
          onCalendarChange={handleDatesChange}
          className={styles.range_picker}
          renderNumOfNights={true}
          disablePastDates={true}
          defaultValue={[
            searchParams.checkinDate || searchParams.pickupDate || null,
            searchParams.checkoutDate || searchParams.dropoffDate || null,
          ]}
          type={searchParams.type}
          showTime={isCar}
        />

        {isAccommodationProperty(searchParams.type) && (
          <div className={styles.twocols}>
            <Dropdown
              show={isOpen}
              onToggle={handleToggleDropdown}
              title={dropdownTitle}
              className={styles.occupancyDropDown}
              layout="vertical"
            >
              <OccupancySettingsForm
                bookingParams={searchParams}
                onClose={handleToggleDropdown}
                onChange={handleChangeOccupancy}
              />
            </Dropdown>
            <CurrencySelectControlled value={searchParams.currency} onChange={handleCurrencyChange} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderSearch;
