// Generated with util/create-component.js
import React, { useContext, useEffect, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnsType } from 'antd/lib/table';
import { TablePaginationConfig } from 'antd/es/table';
import { Table, Dropdown, Tooltip, Drawer, Row, Col, Button, MenuProps } from 'antd';
import {
  DownOutlined,
  EditOutlined,
  PlusCircleOutlined,
  CopyOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';

import { AdminResellersDataContext, AdminResellersActionsContext } from 'containers/data_context';
import SearchInput from 'components/search_input';
import DRAWER_SIZE from 'constants/drawer_size';

import ResellerForm from '../reseller_form/reseller_form';
import styles from './resellers_list.module.scss';
import { IReseller } from './resellers_list.types';

const ResellersList: React.FC = () => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 50,
  });

  const [editReseller, setEditReseller] = useState<IReseller | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [openedAddResellerForm, toggleAddAgencyForm] = useState(false);

  const { loadResellers, lockOverrideReseller } = useContext(AdminResellersActionsContext);
  const {
    resellers: { data, isLoading },
  } = useContext(AdminResellersDataContext);

  useEffect(() => {
    loadResellers({ ...pagination, ...(searchTerm && { q: searchTerm }) });
  }, [loadResellers, lockOverrideReseller, searchTerm, pagination]);

  const handleCloseEditForm = () => {
    setEditReseller(null);
  };

  const handleOpenEditResellerForm = (reseller: IReseller) => {
    setEditReseller(reseller);
  };

  const toggleLockOverrideReseller = (reseller: IReseller) => {
    lockOverrideReseller({ agentId: reseller.id, lockOverride: !reseller.lockOverride });
  };

  const handleToggleAddResellerForm = () => {
    toggleAddAgencyForm(!openedAddResellerForm);
  };

  const renderMenu = (reseller: IReseller): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'edit',
        label: t('general.edit'),
        icon: <EditOutlined />,
        onClick: () => handleOpenEditResellerForm(reseller),
      },
      {
        key: 'lock_override',
        label: reseller.lockOverride ? t('general.disable_lock_override') : t('general.enable_lock_override'),
        icon: reseller.lockOverride ? <LockOutlined /> : <UnlockOutlined />,
        onClick: () => toggleLockOverrideReseller(reseller),
      },
    ];

    return { items: menuItems };
  };

  const renderActionButtons = (reseller: IReseller) => {
    return (
      <Dropdown menu={renderMenu(reseller)}>
        <div className={styles.actions_link}>
          <span className={styles.action_link_text}>{t('general.actions')}</span> <DownOutlined />
        </div>
      </Dropdown>
    );
  };

  const handleCopy = (value: string) => navigator.clipboard.writeText(value);

  const renderExternalID = (reseller: IReseller) => {
    return (
      <div className={styles.external_id_container}>
        {reseller.externalId}
        <Tooltip title={t('general.copy')}>
          <CopyOutlined
            color="#175b90"
            className={styles.copy_option}
            onClick={() => handleCopy(reseller.externalId || '')}
          />
        </Tooltip>
      </div>
    );
  };

  const resellersListColumns: ColumnsType<IReseller> = useMemo(
    () => [
      {
        title: t('general.other.email'),
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: t('general.other.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('bookings_list.external_id_title'),
        key: 'externalId',
        render: (reseller: IReseller) => renderExternalID(reseller),
      },
      {
        key: 'actions',
        render: (reseller: IReseller) => renderActionButtons(reseller),
      },
    ],
    [],
  );

  const handlePageChange = useCallback(async (currentPagination: TablePaginationConfig) => {
    const { current, pageSize } = currentPagination;
    const pagination = {
      page: current || 1,
      perPage: pageSize || 50,
    };

    setPagination(pagination);
  }, []);

  const handleSearchTermChange = useCallback(async (term: string) => {
    setPagination({
      page: 1,
      perPage: 50,
    });

    setSearchTerm(term);
  }, []);

  return (
    <div data-testid="ResellersList" className={styles.root}>
      <Row justify="space-between" align="middle" className={styles.header_container} gutter={[16, 16]}>
        <Col lg={20} sm={24} xs={24}>
          <SearchInput onSearch={handleSearchTermChange} placeholder={t('reseller.search_label')} />
        </Col>
        <Col lg={3} offset={1} sm={24} xs={23}>
          <Button
            onClick={handleToggleAddResellerForm}
            type="primary"
            size="large"
            icon={<PlusCircleOutlined />}
            className={styles.create_button}
          >
            {t('reseller.add')}
          </Button>
        </Col>
      </Row>
      <Table
        loading={isLoading}
        scroll={{ y: window.innerHeight - 300 }}
        columns={resellersListColumns}
        dataSource={data?.agents}
        pagination={{
          current: pagination?.page,
          total: data?.total,
          pageSize: pagination?.perPage,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onChange={handlePageChange}
        rowKey="id"
      />

      {editReseller && (
        <Drawer
          open={!!editReseller}
          title={t('general.buttons.edit')}
          width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
          onClose={handleCloseEditForm}
          destroyOnClose={true}
        >
          <ResellerForm reseller={editReseller} onCancel={handleCloseEditForm} />
        </Drawer>
      )}

      <Drawer
        open={openedAddResellerForm}
        title={t('reseller.add')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
        onClose={handleToggleAddResellerForm}
        destroyOnClose={true}
      >
        <ResellerForm onCancel={handleToggleAddResellerForm} />
      </Drawer>
    </div>
  );
};

export default ResellersList;
