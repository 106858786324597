import React from 'react';
import { useTranslation } from 'react-i18next';

import { Select, DatePicker } from 'antd';
import { IGiftCardOrdersFiltersProps } from './gift_card_orders_filters.types';
import styles from './gift_card_orders_filters.module.scss';

const { Option } = Select;

const GiftCardOrdersFilters: React.FC<IGiftCardOrdersFiltersProps> = ({
  onDatePickerChange,
  onSelectBooked,
  onSelectKind,
  onSelectDeliveryStatus,
  onSelectDeliveryMethod,
  openDatePicker,
  setOpenDatePicker,
}) => {
  const { t } = useTranslation();

  return (
    <div data-testid="GiftCardOrdersFilters" className={styles.root}>
      <div className={styles.flexContainer}>
        <DatePicker.RangePicker
          data-testid="DatePicker"
          variant="borderless"
          onChange={onDatePickerChange}
          open={openDatePicker}
          onOpenChange={setOpenDatePicker}
          className={`${styles.flexStatic} ${styles.rangePicker}`}
        />
        <Select
          data-testid="SelectStatus"
          variant="borderless"
          onChange={onSelectBooked}
          placeholder={t('gift_card.orders_filter_status')}
          className={`${styles.flexStatic} ${styles.selectStatus}`}
        >
          <Option value="unused">{t('gift_card.orders_filter_available')}</Option>
          <Option value="used">{t('gift_card.orders_filter_redeemed')}</Option>
          <Option value="anyStatus">{t('gift_card.orders_filter_any')}</Option>
        </Select>
        <Select
          data-testid="SelectDeliveryStatus"
          variant="borderless"
          onChange={onSelectDeliveryStatus}
          placeholder={t('gift_card.delivery_status')}
          className={`${styles.flexStatic} ${styles.selectDelivery}`}
        >
          <Option value="sent">{t('gift_card.orders_filter_sent')}</Option>
          <Option value="notSent">{t('gift_card.orders_filter_not_sent')}</Option>
          <Option value="anySent">{t('gift_card.orders_filter_any')}</Option>
        </Select>
        <Select
          data-testid="SelectDeliveryMethod"
          variant="borderless"
          onChange={onSelectDeliveryMethod}
          placeholder={t('gift_card.order_delivery_method')}
          className={`${styles.flexStatic} ${styles.selectDelivery}`}
        >
          <Option value="email">{t('general.other.email')}</Option>
          <Option value="letterMail">{t('gift_card.orders_letter_mail')}</Option>
          <Option value="anyMail">{t('gift_card.orders_filter_any')}</Option>
        </Select>
        <Select
          data-testid="SelectKind"
          variant="borderless"
          onChange={onSelectKind}
          placeholder={t('gift_card.orders_filter_kind')}
          className={`${styles.flexStatic} ${styles.selectKind}`}
        >
          <Option value="giftCard">{t('gift_card')}</Option>
          <Option value="upsell">{t('gift_card.orders_filter_upsell')}</Option>
          <Option value="anyKind">{t('gift_card.orders_filter_any')}</Option>
        </Select>
      </div>
    </div>
  );
};

export default GiftCardOrdersFilters;
