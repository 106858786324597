import dayjs from 'dayjs';
import currencies from 'world-currencies';

import getUrlParams from 'utils/get_url_params';

import { decodeMapParams } from './map_params';
import isCarProperty from './is_car_property';

const DEFAULT_OCCUPANCY_PARAMS = {
  children: 0,
  adults: 1,
};

export default function getBookingParamsFromUrl() {
  const {
    type,
    currency,
    checkinDate,
    checkoutDate,
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
    driverAge,
    driverCountry,
    adults,
    children,
    childrenAge,
    mapCoordinates,
    city,
    country,
    state,
    title
  } = getUrlParams();


  const isCar = isCarProperty(type);
  const optionalParams = isCar ? { driverAge, driverCountry } : { ...DEFAULT_OCCUPANCY_PARAMS };
  const startDate = isCar ? pickupDate : checkinDate;
  const endDate = isCar ? dropoffDate : checkoutDate;

  const parsedStartDate = dayjs(startDate + ' ' + (pickupTime || '00:00'));
  const parsedEndDate = dayjs(endDate + ' ' + (dropoffTime || '00:00'));

  if (startDate && parsedStartDate.isValid()) {
    const isCheckinAfterCurrent = dayjs().isSameOrBefore(parsedStartDate, 'day');

    optionalParams[isCar ? 'pickupDate' : 'checkinDate'] = isCheckinAfterCurrent ? parsedStartDate : null;
    optionalParams.checkinDate = isCheckinAfterCurrent ? parsedStartDate : null;
  }

  if (endDate && parsedEndDate.isValid()) {
    const parsedCheckinDate = isCar ? optionalParams.pickupDate : optionalParams.checkinDate;
    const isCheckoutValid = parsedCheckinDate && dayjs(parsedCheckinDate).startOf('day').isBefore(dayjs(parsedEndDate).startOf('day'));
    optionalParams[isCar ? 'dropoffDate' : 'checkoutDate'] = isCheckoutValid ? parsedEndDate : null;
    optionalParams.checkoutDate = isCheckoutValid ? parsedEndDate : null;
  }

  if (pickupTime && parsedStartDate.isValid()) {
    optionalParams.pickupTime = pickupTime;
  }

  if (dropoffTime && parsedEndDate.isValid()) {
    optionalParams.dropoffTime = dropoffTime;
  }

  if (!isCar) {
    if (childrenAge) {
      const processedChildrenAge = childrenAge.split(',').map(val => (val ? Number(val) : null));

      optionalParams.childrenAge = processedChildrenAge;
    }

    const parsedChildrenAmount = Number(children);
    if (!Number.isNaN(parsedChildrenAmount)) {
      optionalParams.children = Math.max(DEFAULT_OCCUPANCY_PARAMS.children, parsedChildrenAmount);
    }

    const parsedAdultsAmount = Number(adults);
    if (!Number.isNaN(parsedAdultsAmount)) {
      optionalParams.adults = Math.max(DEFAULT_OCCUPANCY_PARAMS.adults, parsedAdultsAmount);
    }
  }

  const isValidCurrency = currencies[currency];
  if (isValidCurrency) {
    optionalParams.currency = currency;
  }

  if (country) {
    optionalParams.country = country;
  }

  if (city) {
    optionalParams.city = city;
  }

  if (state) {
    optionalParams.state = state;
  }

  if (type) {
    optionalParams.type = type;
  }

  if (title) {
    optionalParams.title = title;
  }

  try {
    optionalParams.mapCoordinates = decodeMapParams(mapCoordinates);
  } catch (_error) {
    optionalParams.mapCoordinates = null;
  }

  return optionalParams;
}
