import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as Sentry from '@sentry/react';
import './i18n';

import App from './app';
import './index.css';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

if (window.location.host === 'app.reserva.is') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay({ maskAllText: false, blockAllMedia: false })],
    ...(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE && {
      tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    }),
    ...(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE && {
      replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
    }),
    ...(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE && {
      replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
    }),
    ...(process.env.REACT_APP_VERSION && {
      release: process.env.REACT_APP_VERSION,
    }),
  });
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <I18nextProvider i18n={i18next}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </I18nextProvider>,
);
