// Generated with util/create-component.js
import React, { useContext, useEffect, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TablePaginationConfig } from 'antd/es/table';
import { Table, Dropdown, Drawer, Row, Col, Button, MenuProps } from 'antd';
import { DownOutlined, EditOutlined, LockOutlined, PlusCircleOutlined, UnlockOutlined } from '@ant-design/icons';

import { AdminPropertiesDataContext, AdminPropertiesActionsContext } from 'containers/data_context';
import SearchInput from 'components/search_input';
import DRAWER_SIZE from 'constants/drawer_size';

import PropertyForm from '../property_form/property_form';
import { IProperty } from './properties_list.types';
import styles from './properties_list.module.scss';

const PropertiesList: React.FC = () => {
  const { t } = useTranslation();

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 50,
  });

  const [editProperty, setEditProperty] = useState<IProperty | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [openedAddPropertyForm, toggleAddPropertyForm] = useState(false);

  const { loadProperties, lockOverrideSupplier } = useContext(AdminPropertiesActionsContext);
  const {
    properties: { data, isLoading },
  } = useContext(AdminPropertiesDataContext);

  useEffect(() => {
    loadProperties({ ...pagination, ...(searchTerm && { q: searchTerm }) });
  }, [loadProperties, searchTerm, pagination]);

  const handleCloseEditForm = () => {
    setEditProperty(null);
  };

  const handleOpenEditPropertyForm = (property: IProperty) => {
    setEditProperty(property);
  };

  const toggleLockOverrideSupplier = (property: IProperty) => {
    lockOverrideSupplier({ supplierId: property.id, lockOverride: !property.lockOverride });
  };

  const handleToggleAddPropertyForm = () => {
    toggleAddPropertyForm(!openedAddPropertyForm);
  };

  const renderMenu = (property: IProperty): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'edit',
        label: t('general.edit'),
        icon: <EditOutlined />,
        onClick: () => handleOpenEditPropertyForm(property),
      },
      {
        key: 'lock_override',
        label: property.lockOverride ? t('general.disable_lock_override') : t('general.enable_lock_override'),
        icon: property.lockOverride ? <LockOutlined /> : <UnlockOutlined />,
        onClick: () => toggleLockOverrideSupplier(property),
      },
    ];
    return { items: menuItems };
  };

  const renderActionButtons = (Hotel: IProperty) => {
    return (
      <Dropdown menu={renderMenu(Hotel)}>
        <div className={styles.actions_link}>
          <span className={styles.action_link_text}>{t('general.actions')}</span> <DownOutlined />
        </div>
      </Dropdown>
    );
  };

  const propertiesListColumns = useMemo(
    () => [
      {
        title: t('seller.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t('general.other.email'),
        dataIndex: 'email',
        key: 'email',
      },
      {
        key: 'actions',
        render: (property: IProperty) => renderActionButtons(property),
      },
    ],
    [],
  );

  const handlePageChange = useCallback(async (currentPagination: TablePaginationConfig) => {
    const { current, pageSize } = currentPagination;
    const pagination = {
      page: current || 1,
      perPage: pageSize || 50,
    };

    setPagination(pagination);
  }, []);

  const handleSearchTermChange = useCallback(async (term: string) => {
    setPagination({
      page: 1,
      perPage: 50,
    });

    setSearchTerm(term);
  }, []);

  return (
    <div data-testid="PropertiesList" className={styles.root}>
      <Row justify="space-between" align="middle" className={styles.header_container} gutter={[16, 16]}>
        <Col lg={20} sm={24} xs={24}>
          <SearchInput onSearch={handleSearchTermChange} placeholder={t('property.search')} />
        </Col>
        <Col lg={3} offset={1} sm={24} xs={23}>
          <Button
            onClick={handleToggleAddPropertyForm}
            type="primary"
            size="large"
            icon={<PlusCircleOutlined />}
            className={styles.create_button}
          >
            {t('property.add')}
          </Button>
        </Col>
      </Row>
      <Table
        loading={isLoading}
        scroll={{ y: window.innerHeight - 300 }}
        columns={propertiesListColumns}
        dataSource={data?.reservaHotels}
        pagination={{
          current: pagination?.page,
          total: data?.total,
          pageSize: pagination?.perPage,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onChange={handlePageChange}
        rowKey="id"
      />

      {editProperty && (
        <Drawer
          open={!!editProperty}
          title={t('general.buttons.edit')}
          width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
          onClose={handleCloseEditForm}
          destroyOnClose={true}
        >
          <PropertyForm property={editProperty} onCancel={handleCloseEditForm} />
        </Drawer>
      )}

      <Drawer
        open={openedAddPropertyForm}
        title={t('property.add')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
        onClose={handleToggleAddPropertyForm}
        destroyOnClose={true}
      >
        <PropertyForm onCancel={handleToggleAddPropertyForm} />
      </Drawer>
    </div>
  );
};

export default PropertiesList;
