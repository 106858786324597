import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tabs, Drawer, notification } from 'antd';

import GIFTCARD_STATUS from 'constants/gift_card_status';

import {
  PropertyGiftCardsActionsContext,
  PropertyGiftCardsDataContext,
  PropertySettingsActionsContext,
  PropertySettingsDataContext,
  SupplierContractsActionsContext,
  SupplierContractsDataContext,
} from 'containers/data_context';

import dateFormatter from 'utils/date_formatter';
import GIFT_CARD_DRAWER_SIZE from 'constants/gift_card_drawer_size';

import Loading from 'components/loading';
import CreatedGiftCards from 'components/gift_card_list/created_gift_cards/created_gift_cards';
import ArchivedGiftCards from 'components/gift_card_list/archived_gift_cards/archived_gift_cards';
import PublishedGiftCards from 'components/gift_card_list/published_gift_cards/published_gift_cards';
import GiftCardDetails from 'components/gift_card_details/gift_card_details';

import { IPhoto, IUpsellItem, IPropertyUpsellRate } from 'components/gift_card_form/gift_card_form.types';
import { IPublishedGiftCard } from 'components/gift_card_list/published_gift_cards/published_gift_cards.types';
import { ICreatedGiftCard } from 'components/gift_card_list/created_gift_cards/created_gift_cards.types';
import { IGiftCardFormValues } from 'components/gift_card_form/gift_card_form.types';
import { IFilledSection } from 'components/gift_card_pdf_details/gift_card_pdf_details.types';

import styles from './gift_card_page.module.scss';
import { IGiftCardTypeSizes } from './gift_card_page.types';

const GiftCardPage: React.FC = () => {
  const { t } = useTranslation();

  const propertyGiftCardsActions = useContext(PropertyGiftCardsActionsContext);
  const propertySettingsActions = useContext(PropertySettingsActionsContext);
  const { getGiftCardPdfTemplates, getGiftCardPdfTemplateFilledSections } = useContext(PropertyGiftCardsActionsContext);
  const { loadSupplierRatePlansList } = useContext(SupplierContractsActionsContext);
  const hotelGiftCardsData = useContext(PropertyGiftCardsDataContext);
  const supplierContractsData = useContext(SupplierContractsDataContext);
  const propertySettingsData = useContext(PropertySettingsDataContext);

  const {
    resellerUnits: { isLoading: isLoadingRatePlans },
  } = supplierContractsData;

  const {
    propertiesList: { data: properties },
    selectedProperty,
    propertyGroupGiftCardsStores: { data: propertyGroupGiftCardsStoresData },
  } = propertySettingsData;

  const {
    giftCardTypes: { data, isLoading },
    hotelRoomTypeRatePlans: { data: hotelRoomTypeRatePlansData, isLoading: hotelRoomTypeRatePlansIsLoading },
    giftCardTypeSizes: { data: giftCardTypeSizesData, isLoading: giftCardTypeSizesIsLoading },
    giftCardPdfTemplates: { data: pdfTemplates, isLoading: pdfTemplatesIsLoading },
    giftCardPdfTemplateFilledSections: { data: filledSections, isLoading: filledSectionsIsLoading },
  } = hotelGiftCardsData;

  const [selectedTab, setSelectedTab] = useState(GIFTCARD_STATUS.PUBLISHED);
  const [inventory, setInventory] = useState<Array<IGiftCardTypeSizes>>([]);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  useEffect(() => {
    async function initApp() {
      if (selectedProperty) {
        try {
          await propertyGiftCardsActions.getUserHotelRoomTypeRatePlans(selectedProperty);
          await propertyGiftCardsActions.getGiftCardTypes({ propertyId: selectedProperty });
          await propertyGiftCardsActions.getGiftCardTypeSizes({ propertyId: selectedProperty });
          await propertySettingsActions.loadPropertyGroupGiftCardsStores(selectedProperty);
        } catch (e) {
          console.error('error', e);
        }
      }
    }
    initApp();
  }, [propertyGiftCardsActions, propertySettingsActions, selectedProperty]);

  useEffect(() => {
    if (giftCardTypeSizesData) {
      setInventory(giftCardTypeSizesData);
    }
  }, [giftCardTypeSizesData]);

  const allGiftCards = useMemo(() => {
    return data?.reduce((giftCards: ICreatedGiftCard[], giftCard: ICreatedGiftCard) => {
      if (giftCard.status == GIFTCARD_STATUS.PUBLISHED || giftCard.status == GIFTCARD_STATUS.CREATED) {
        const size = inventory?.find((size: IGiftCardTypeSizes) => size.id === giftCard.id)?.size || 0;
        giftCards.push({
          ...giftCard,
          inventory: `${giftCard.limit - size || 0}/${giftCard.limit}`,
        });
      } else {
        giftCards.push({
          ...giftCard,
          inventory: giftCard.limit,
        });
      }
      return giftCards;
    }, []);
  }, [data, inventory]);

  const archivedGiftCards = useMemo(
    () => allGiftCards?.filter((giftCard: ICreatedGiftCard) => giftCard.status == GIFTCARD_STATUS.ARCHIVED),
    [allGiftCards],
  );
  const createdGiftCards = useMemo(
    () => allGiftCards?.filter((giftCard: ICreatedGiftCard) => giftCard.status == GIFTCARD_STATUS.CREATED),
    [allGiftCards],
  );

  const publishedGiftCards = useMemo(
    () => allGiftCards?.filter((giftCard: ICreatedGiftCard) => giftCard.status == GIFTCARD_STATUS.PUBLISHED),
    [allGiftCards],
  );

  const handleLoadPropertyRatePlans = useCallback(() => {
    if (selectedProperty) {
      loadSupplierRatePlansList(selectedProperty);
    }
  }, [loadSupplierRatePlansList, selectedProperty]);

  const handleLoadPdfTemplates = useCallback(() => {
    if (selectedProperty) {
      getGiftCardPdfTemplates({ propertyId: selectedProperty });
    }
  }, [getGiftCardPdfTemplates, selectedProperty]);

  const handleLoadFilledSections = useCallback(
    (giftCardTypeId: number, pdfTemplateId: number) => {
      if (selectedProperty) {
        getGiftCardPdfTemplateFilledSections({
          propertyId: selectedProperty,
          giftCardTypeId: giftCardTypeId,
          pdfTemplateId: pdfTemplateId,
        });
      }
    },
    [getGiftCardPdfTemplateFilledSections, selectedProperty],
  );

  const openDrawer = useCallback(() => {
    setShowDrawer(true);
    handleLoadPropertyRatePlans();
    setInventory([]);
  }, [selectedProperty, loadSupplierRatePlansList]);

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
  };

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: 'Gift card unpublished successfully.',
      });
    } else {
      return notification['error']({
        message: t('reserva.general.error_message'),
        description: t('reserva.general.error_description'),
      });
    }
  };

  const getGiftCardTypeParams = async (giftCard: IGiftCardFormValues, formData = new FormData()) => {
    Object.keys(giftCard).map(key => {
      if (
        [
          'title',
          'price',
          'expirationDuration',
          'expirationDurationUnit',
          'status',
          'limit',
          'offerLetterDelivery',
          'offerPickupAtHotel',
        ].includes(key as keyof ICreatedGiftCard)
      ) {
        formData.append(
          `gift_card_type_params[${key.replace(/[A-Z]/g, (char: string) => `_${char.toLowerCase()}`)}]`,
          giftCard[key as keyof IGiftCardFormValues] as string | Blob,
        );
      }
    });

    if (giftCard?.storeIds) {
      giftCard.storeIds.forEach((storeId: number) => {
        formData.append(`gift_card_type_params[store_ids][]`, `${storeId}`);
      });
    }

    if (giftCard?.settings?.lengthOfStay) {
      formData.append(`gift_card_type_params[settings][length_of_stay]`, giftCard.settings.lengthOfStay.toString());
    }

    if (giftCard?.settings?.reservationValue !== undefined) {
      formData.append(
        `gift_card_type_params[settings][reservation_value]`,
        giftCard.settings.reservationValue.toString(),
      );
    }

    formData.append(`gift_card_type_params[description]`, giftCard.description);

    if (giftCard.giftcardType === 0 && giftCard.ratePlans) {
      for (const propertyRate of giftCard.ratePlans) {
        formData.append(`gift_card_type_params[rate_plan_ids][]`, propertyRate[2]);
      }

      if (giftCard.upsellTable) {
        giftCard.upsellTable.forEach((upsellRate: IUpsellItem) => {
          const existingRate = giftCard?.upsellRatePlans?.find(
            (upsell: IPropertyUpsellRate) =>
              upsell.ratePlanId === upsellRate.ratePlanId && upsell.propertyId === upsellRate.propertyId,
          );

          if (existingRate && existingRate.id && !giftCard.isDuplicate) {
            // If rate is found we update it
            formData.append(`gift_card_type_params[upsell_rate_plans_attributes][][id]`, existingRate.id.toString());
            formData.append(
              `gift_card_type_params[upsell_rate_plans_attributes][][rate_plan_id]`,
              upsellRate.ratePlanId,
            );
            formData.append(
              `gift_card_type_params[upsell_rate_plans_attributes][][rate_plan_title]`,
              upsellRate.ratePlan,
            );
            formData.append(
              `gift_card_type_params[upsell_rate_plans_attributes][][room_type_id]`,
              upsellRate.roomTypeId,
            );
            formData.append(
              `gift_card_type_params[upsell_rate_plans_attributes][][room_type_title]`,
              upsellRate.roomType,
            );
            if (upsellRate.price) {
              formData.append(
                `gift_card_type_params[upsell_rate_plans_attributes][][price]`,
                upsellRate.price?.toString(),
              );
            }
          } else {
            // If it's not found we are creating
            formData.append(
              `gift_card_type_params[upsell_rate_plans_attributes][][rate_plan_id]`,
              upsellRate.ratePlanId,
            );
            formData.append(
              `gift_card_type_params[upsell_rate_plans_attributes][][rate_plan_title]`,
              upsellRate.ratePlan,
            );
            formData.append(
              `gift_card_type_params[upsell_rate_plans_attributes][][room_type_id]`,
              upsellRate.roomTypeId,
            );
            formData.append(
              `gift_card_type_params[upsell_rate_plans_attributes][][room_type_title]`,
              upsellRate.roomType,
            );
            if (upsellRate.price) {
              formData.append(
                `gift_card_type_params[upsell_rate_plans_attributes][][price]`,
                upsellRate.price?.toString(),
              );
            }
          }
        });
      }

      if (giftCard.upsellRatePlans && !giftCard?.isDuplicate) {
        // If rates have been removed then they have to be deleted

        // if table is cleared then all rates need to be removed

        const ratesToDelete = giftCard.upsellRatePlans.filter(
          initialUpsell =>
            !giftCard?.upsellTable?.some(selectedUpsell => initialUpsell.ratePlanId === selectedUpsell.ratePlanId),
        );

        for (const rate of ratesToDelete) {
          if (rate.id) {
            formData.append(`gift_card_type_params[upsell_rate_plans_attributes][][id]`, rate.id.toString());
            formData.append(`gift_card_type_params[upsell_rate_plans_attributes][][_destroy]`, 'true');
          }
        }
      }
    }

    for (const photo of giftCard.photos || []) {
      if (photo.file) {
        formData.append('gift_card_type_params[photos][]', photo.file as Blob);
      } else if (photo.publicUrl && giftCard?.isDuplicate) {
        formData.append('gift_card_type_params[photos][]', photo.publicUrl);
      }
    }

    if (giftCard.netPrice) {
      formData.append(`gift_card_type_discount_params[title]`, 'GiftCard Discount');
      formData.append(`gift_card_type_discount_params[value]`, (giftCard.price - giftCard.netPrice).toString());
      formData.append(`gift_card_type_discount_params[discount_type]`, 'fixed_amount');
      formData.append(`gift_card_type_discount_params[status]`, 'active');
    }

    if (giftCard.absoluteExpirationDate || giftCard.absoluteExpirationDate === null) {
      const formattedExpirationDate = dateFormatter.toApi(giftCard.absoluteExpirationDate);
      if (formattedExpirationDate !== null) {
        formData.append(`gift_card_type_params[absolute_expiration_date]`, formattedExpirationDate);
      } else {
        formData.append(`gift_card_type_params[absolute_expiration_date]`, '');
      }
    }

    return formData;
  };

  const getMultiGiftCardTypeParams = async (giftCard: IGiftCardFormValues, formData = new FormData()) => {
    Object.keys(giftCard).map(key => {
      if (
        [
          'title',
          'price',
          'expirationDuration',
          'expirationDurationUnit',
          'status',
          'limit',
          'offerLetterDelivery',
          'offerPickupAtHotel',
        ].includes(key)
      ) {
        formData.append(
          `multi_property_gift_params[${key.replace(/[A-Z]/g, (char: string) => `_${char.toLowerCase()}`)}]`,
          giftCard[key as keyof IGiftCardFormValues] as string | Blob,
        );
      }
    });

    if (giftCard?.storeIds) {
      giftCard.storeIds.forEach((storeId: number) => {
        formData.append(`multi_property_gift_params[store_ids][]`, `${storeId}`);
      });
    }

    if (giftCard.settings && giftCard.settings.lengthOfStay) {
      formData.append(
        `multi_property_gift_params[settings][length_of_stay]`,
        giftCard.settings.lengthOfStay.toString(),
      );
    }

    if (giftCard?.settings?.reservationValue !== undefined) {
      formData.append(
        `multi_property_gift_params[settings][reservation_value]`,
        giftCard.settings.reservationValue.toString(),
      );
    }

    formData.append(`multi_property_gift_params[description]`, giftCard.description);

    for (const photo of giftCard.photos || []) {
      if (photo.file) {
        formData.append('multi_property_gift_params[photos][]', photo.file as Blob);
      } else if (photo.publicUrl && giftCard?.isDuplicate) {
        formData.append('multi_property_gift_params[photos][]', photo.publicUrl);
      }
    }

    if (giftCard.ratePlans) {
      for (const propertyRate of giftCard.ratePlans) {
        formData.append(`multi_property_gift_params[property_rate_plans][][channex_property_id]`, propertyRate[0]);
        formData.append(`multi_property_gift_params[property_rate_plans][][rate_plan_ids][]`, propertyRate[2]);
      }
    }

    if (giftCard.upsellTable) {
      giftCard.upsellTable.forEach((upsellRate: IUpsellItem) => {
        if (upsellRate.id && !giftCard.isDuplicate) {
          formData.append(`multi_property_gift_params[upsell_rate_plans_attributes][][id]`, upsellRate.id.toString());
        }
        if (upsellRate.propertyId) {
          formData.append(
            `multi_property_gift_params[upsell_rate_plans_attributes][][property_id]`,
            upsellRate.propertyId,
          );
        }
        if (upsellRate.ratePlanId) {
          formData.append(
            `multi_property_gift_params[upsell_rate_plans_attributes][][rate_plan_id]`,
            upsellRate.ratePlanId,
          );
        }
        if (upsellRate.ratePlan) {
          formData.append(
            `multi_property_gift_params[upsell_rate_plans_attributes][][rate_plan_title]`,
            upsellRate.ratePlan,
          );
        }
        formData.append(
          `multi_property_gift_params[upsell_rate_plans_attributes][][room_type_id]`,
          upsellRate.roomTypeId,
        );
        if (upsellRate.roomType) {
          formData.append(
            `multi_property_gift_params[upsell_rate_plans_attributes][][room_type_title]`,
            upsellRate.roomType,
          );
        }
        if (upsellRate.price) {
          formData.append(
            `multi_property_gift_params[upsell_rate_plans_attributes][][price]`,
            upsellRate.price?.toString(),
          );
        }
      });

      if (giftCard.multiPropertyUpsellRatePlans && !giftCard?.isDuplicate) {
        // If rates have been removed then they have to be deleted
        const ratePlanIds = giftCard.upsellTable.map(upsell => {
          if (upsell.id) return upsell.ratePlanId;
        });

        const allUpsellRP: IPropertyUpsellRate[] = ([] as IPropertyUpsellRate[]).concat(
          ...giftCard.multiPropertyUpsellRatePlans,
        );

        const deletedUpsellRP = allUpsellRP.filter((ur: IPropertyUpsellRate) => {
          return !ratePlanIds.includes(ur.ratePlanId) && ur.id;
        });

        if (deletedUpsellRP?.length) {
          deletedUpsellRP.map((rate: IPropertyUpsellRate) => {
            formData.append(`multi_property_gift_params[upsell_rate_plans_attributes][][property_id]`, rate.propertyId);
            if (rate.id) {
              formData.append(`multi_property_gift_params[upsell_rate_plans_attributes][][id]`, rate.id.toString());
            }
            formData.append(`multi_property_gift_params[upsell_rate_plans_attributes][][_destroy]`, 'true');
          });
        }
      }
    }

    if (giftCard.netPrice) {
      formData.append(`multi_property_gift_params[discount][title]`, 'GiftCard Discount');
      formData.append(`multi_property_gift_params[discount][value]`, (giftCard.price - giftCard.netPrice).toString());
      formData.append(`multi_property_gift_params[discount][discount_type]`, 'fixed_amount');
      formData.append(`multi_property_gift_params[discount][status]`, 'active');
    }

    if (giftCard.absoluteExpirationDate || giftCard.absoluteExpirationDate === null) {
      const formattedExpirationDate = dateFormatter.toApi(giftCard.absoluteExpirationDate);
      if (formattedExpirationDate !== null) {
        formData.append(`multi_property_gift_params[absolute_expiration_date]`, formattedExpirationDate);
      } else {
        formData.append(`multi_property_gift_params[absolute_expiration_date]`, '');
      }
    }

    return formData;
  };

  const getGiftCardTypeFilledSectionsParams = async (
    filledSections: IFilledSection[],
    selectedTemplate: number,
    isMultiProperty: boolean,
    isDuplicate: boolean,
    giftCardTypeParams = new FormData(),
  ) => {
    const dataPrefix = isMultiProperty ? 'multi_property_gift_params' : 'gift_card_type_params';
    giftCardTypeParams.append(`${dataPrefix}[pdf_template_id]`, selectedTemplate.toString());

    let index = 0;
    for (const filledSection of filledSections) {
      if (filledSection.dashSize) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][dash_size]`,
          filledSection.dashSize.toString(),
        );
      }

      if (filledSection.dashSpace) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][dash_space]`,
          filledSection.dashSpace.toString(),
        );
      }

      if (filledSection.font) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][font]`,
          filledSection.font.toString(),
        );
      }

      if (filledSection.fontSize) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][font_size]`,
          filledSection.fontSize.toString(),
        );
      }

      if (filledSection.opacity) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][opacity]`,
          filledSection.opacity.toString(),
        );
      }

      if (filledSection.paddingBottom !== undefined && filledSection.paddingBottom !== null) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][padding_bottom]`,
          filledSection.paddingBottom.toString(),
        );
      }

      if (filledSection.paddingLeft !== undefined && filledSection.paddingLeft !== null) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][padding_left]`,
          filledSection.paddingLeft.toString(),
        );
      }

      if (filledSection.paddingRight !== undefined && filledSection.paddingRight !== null) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][padding_right]`,
          filledSection.paddingRight.toString(),
        );
      }

      if (filledSection.paddingTop !== undefined && filledSection.paddingTop !== null) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][padding_top]`,
          filledSection.paddingTop.toString(),
        );
      }

      if (filledSection.text) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][text]`,
          filledSection.text.toString(),
        );
      }

      if (filledSection.textAlign) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][text_align]`,
          filledSection.textAlign.toString(),
        );
      }

      if (filledSection.fillColor !== undefined && filledSection.fillColor !== null) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][fill_color]`,
          filledSection.fillColor.toString(),
        );
      }

      if (filledSection.cornerRadius !== undefined && filledSection.cornerRadius !== null) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][corner_radius]`,
          filledSection.cornerRadius.toString(),
        );
      }

      if (isDuplicate && 'photoUrl' in filledSection) {
        if (filledSection.photoUrl) {
          giftCardTypeParams.append(
            `${dataPrefix}[filled_sections_attributes][${index}][photo]`,
            filledSection.photoUrl,
          );
        } else {
          giftCardTypeParams.append(`${dataPrefix}[filled_sections_attributes][${index}][photo]`, '');
        }
      } else if ('photo' in filledSection) {
        if (filledSection.photo?.file) {
          giftCardTypeParams.append(
            `${dataPrefix}[filled_sections_attributes][${index}][photo]`,
            filledSection.photo.file as Blob,
          );
        } else if (filledSection.photo === null) {
          giftCardTypeParams.append(`${dataPrefix}[filled_sections_attributes][${index}][photo]`, '');
        }
      }

      if (filledSection.pdfSectionId) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][pdf_section_id]`,
          filledSection.pdfSectionId.toString(),
        );
      }

      if (filledSection.stretch !== undefined && filledSection.stretch !== null) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][stretch]`,
          filledSection.stretch.toString(),
        );
      }

      if (filledSection.visible !== undefined && filledSection.visible !== null) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][visible]`,
          filledSection.visible.toString(),
        );
      }

      if (filledSection.id) {
        giftCardTypeParams.append(
          `${dataPrefix}[filled_sections_attributes][${index}][id]`,
          filledSection.id.toString(),
        );
      }

      index = index + 1;
    }

    return giftCardTypeParams;
  };

  const handleUnpublishGiftCard = async (giftCardId: number): Promise<void> => {
    const giftCard = data.find((giftCard: IPublishedGiftCard) => giftCard.id == giftCardId);

    if (giftCard.multiPropertyId) {
      const multiGiftCardTypeParams = await getMultiGiftCardTypeParams(giftCard);
      multiGiftCardTypeParams.append('multi_property_gift_params[status]', GIFTCARD_STATUS.CREATED);

      try {
        await propertyGiftCardsActions.updateMultiGiftCardType({
          propertyId: selectedProperty,
          multiGiftCardTypeParams,
          multiPropertyGiftId: giftCard.multiPropertyId,
        });
        openNotificationWithIcon('success');
      } catch (e) {
        openNotificationWithIcon('error');
      }
    } else {
      const giftCardTypeParams = await getGiftCardTypeParams(giftCard);
      giftCardTypeParams.append('gift_card_type_params[status]', GIFTCARD_STATUS.CREATED);

      try {
        await propertyGiftCardsActions.updateGiftCardType({
          propertyId: selectedProperty,
          giftCardTypeParams,
          giftCardTypeId: giftCard.id,
        });
        openNotificationWithIcon('success');
      } catch (e) {
        openNotificationWithIcon('error');
      }
    }
  };

  const handleUpdateGiftCard = async (giftCard: ICreatedGiftCard, formData = new FormData()) => {
    if (giftCard.multiPropertyId) {
      const multiGiftCardTypeParams = await getMultiGiftCardTypeParams(giftCard, formData);
      await propertyGiftCardsActions.updateMultiGiftCardType({
        propertyId: selectedProperty,
        multiGiftCardTypeParams,
        multiPropertyGiftId: giftCard.multiPropertyId,
      });
    } else {
      const giftCardTypeParams = await getGiftCardTypeParams(giftCard, formData);
      await propertyGiftCardsActions.updateGiftCardType({
        propertyId: selectedProperty,
        giftCardTypeParams,
        giftCardTypeId: giftCard.id,
      });
    }
  };

  const handleSaveChanges = async (
    giftCard: ICreatedGiftCard,
    selectedTemplate: number,
    fSections?: IFilledSection[],
  ) => {
    let formData = new FormData();

    const pdfSections = giftCard?.isDuplicate
      ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (fSections?.length ? fSections : filledSections).map(({ id, ...rest }: IFilledSection) => rest)
      : fSections || [];

    if (pdfSections?.length) {
      formData = await getGiftCardTypeFilledSectionsParams(
        pdfSections,
        selectedTemplate,
        !!giftCard.multiPropertyId,
        !!giftCard?.isDuplicate,
        formData,
      );
    }

    if (giftCard?.id && !giftCard?.isDuplicate) {
      await handleUpdateGiftCard(giftCard, formData);
    } else {
      await handleCreateGiftCard(giftCard, formData);
    }
    closeDrawer();
  };

  const handleCreateGiftCard = async (giftCard: ICreatedGiftCard, formData?: FormData) => {
    if (!formData) {
      formData = new FormData();
    }
    if (giftCard.giftcardType === 1) {
      const multiGiftCardTypeParams = await getMultiGiftCardTypeParams(giftCard, formData);

      await propertyGiftCardsActions.createMultiGiftCardType({
        propertyId: selectedProperty,
        multiGiftCardTypeParams,
      });
    } else {
      const giftCardTypeParams = await getGiftCardTypeParams(giftCard, formData);

      await propertyGiftCardsActions.createGiftCardType({
        propertyId: selectedProperty,
        giftCardTypeParams,
      });
    }
  };

  const handleDeletePhoto = async (giftCard: ICreatedGiftCard, photo: IPhoto) => {
    if (giftCard.multiPropertyId) {
      await propertyGiftCardsActions.deleteMultiGiftCardTypePhoto({
        propertyId: selectedProperty,
        multiPropertyGiftId: giftCard.multiPropertyId,
        giftCardTypeId: giftCard.id,
        photoId: photo.id,
      });
    } else {
      await propertyGiftCardsActions.deleteGiftCardTypePhoto({
        propertyId: selectedProperty,
        giftCardTypeId: giftCard.id,
        photoId: photo.id,
      });
    }
  };

  const createNewGiftCardButton = useMemo(
    () => (
      <Button onClick={openDrawer} type="primary" htmlType="submit" size="large">
        {t('gift_card.create_new')}
      </Button>
    ),
    [selectedProperty, loadSupplierRatePlansList],
  );

  const tabs = [
    {
      label: t('gift_card.published'),
      key: 'published',
      children: (
        <PublishedGiftCards
          giftCards={publishedGiftCards}
          onUpublishGiftCard={handleUnpublishGiftCard}
          isLoadingRatePlans={hotelRoomTypeRatePlansIsLoading}
          onSaveChanges={handleSaveChanges}
          onLoadRatePlans={handleLoadPropertyRatePlans}
          properties={properties}
          selectedProperty={selectedProperty}
          handleDeletePhoto={handleDeletePhoto}
          userHotelRoomTypeRatePlans={hotelRoomTypeRatePlansData}
          pdfTemplates={pdfTemplates}
          isLoadingPdfTemplates={pdfTemplatesIsLoading}
          onLoadPdfTemplates={handleLoadPdfTemplates}
          filledSections={filledSections}
          isLoadingFilledSections={filledSectionsIsLoading}
          onLoadFilledSections={handleLoadFilledSections}
          groupStores={propertyGroupGiftCardsStoresData}
        />
      ),
    },
    {
      label: t('gift_card.created'),
      key: 'created',
      children: (
        <CreatedGiftCards
          giftCards={createdGiftCards}
          isLoadingRatePlans={hotelRoomTypeRatePlansIsLoading}
          onUpdateGiftCard={handleUpdateGiftCard}
          onSaveChanges={handleSaveChanges}
          onLoadRatePlans={handleLoadPropertyRatePlans}
          properties={properties}
          selectedProperty={selectedProperty}
          handleDeletePhoto={handleDeletePhoto}
          userHotelRoomTypeRatePlans={hotelRoomTypeRatePlansData}
          pdfTemplates={pdfTemplates}
          isLoadingPdfTemplates={pdfTemplatesIsLoading}
          onLoadPdfTemplates={handleLoadPdfTemplates}
          filledSections={filledSections}
          isLoadingFilledSections={filledSectionsIsLoading}
          onLoadFilledSections={handleLoadFilledSections}
          groupStores={propertyGroupGiftCardsStoresData}
        />
      ),
    },
    { label: t('gift_card.archived'), key: 'archived', children: <ArchivedGiftCards giftCards={archivedGiftCards} /> },
  ];

  return (
    <div data-testid="GiftCardPage" className={styles.root}>
      {isLoading || giftCardTypeSizesIsLoading || hotelRoomTypeRatePlansIsLoading ? (
        <Loading />
      ) : (
        <div className={styles.tab_root}>
          {window.innerWidth <= 900 && <div className={styles.create_button}>{createNewGiftCardButton}</div>}
          <Tabs
            activeKey={selectedTab}
            onTabClick={handleTabClick}
            items={tabs}
            tabBarExtraContent={window.innerWidth > 900 ? { right: createNewGiftCardButton } : null}
          />
        </div>
      )}
      <Drawer
        destroyOnClose
        title={t('gift_card.create_new')}
        open={showDrawer}
        onClose={closeDrawer}
        width={window.innerWidth > 900 ? GIFT_CARD_DRAWER_SIZE.LARGE : window.innerWidth}
      >
        <GiftCardDetails
          isLoadingRatePlans={isLoadingRatePlans}
          onSaveChanges={handleSaveChanges}
          properties={properties}
          selectedProperty={selectedProperty}
          onDeletePhoto={handleDeletePhoto}
          userHotelRoomTypeRatePlans={hotelRoomTypeRatePlansData}
          pdfTemplates={pdfTemplates}
          isLoadingPdfTemplates={pdfTemplatesIsLoading}
          onLoadPdfTemplates={handleLoadPdfTemplates}
          filledSections={filledSections}
          isLoadingFilledSections={filledSectionsIsLoading}
          onLoadFilledSections={handleLoadFilledSections}
          groupStores={propertyGroupGiftCardsStoresData}
        />
      </Drawer>
    </div>
  );
};

export default GiftCardPage;
