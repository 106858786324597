// Generated with util/create-component.js
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Form } from 'antd';

import { IGiftCardPdfMultipleFormsProps } from './gift_card_pdf_multiple_forms.types';
import GiftCardPdfForm from 'components/gift_card_pdf_details/gift_card_pdf_multiple_forms/gift_card_pdf_form/gift_card_pdf_form';
import Loading from 'components/loading';
import { IFilledSection, IPdfSection } from 'components/gift_card_pdf_details/gift_card_pdf_details.types';
import DEFAULT_VALUES from 'constants/default_template_values';

import styles from './gift_card_pdf_multiple_forms.module.scss';

const GiftCardPdfMultipleForms: React.FC<IGiftCardPdfMultipleFormsProps> = ({
  pdfSections,
  filledSections,
  isDefaultTemplate,
  form,
  giftCard,
}) => {
  const [allSections, setAllSections] = useState<IFilledSection[]>([]);

  useEffect(() => {
    const filledSectionsMap = filledSections.reduce<{ [sectionId: string]: IFilledSection }>((acc, filledSection) => {
      acc[filledSection.pdfSectionId] = filledSection;
      return acc;
    }, {});

    if (Object.keys(filledSectionsMap).length === 0 && isDefaultTemplate === true) {
      const defaultSections = pdfSections.map((section: IPdfSection) => {
        const templateValues = getDefaultTemplateValues(section.name || '');

        return { pdfSection: section, pdfSectionId: section.id, ...templateValues };
      });

      defaultSections.sort((a, b) => (dayjs(a?.pdfSection.id).isBefore(dayjs(b?.pdfSection.id)) ? -1 : 1));

      setAllSections(defaultSections);
    } else {
      const filledAndUnfilledSections = pdfSections.map((section: IPdfSection) => {
        if (filledSectionsMap[section.id]) {
          if (filledSectionsMap[section.id]?.photoUrl) {
            return { ...filledSectionsMap[section.id], photo: { url: filledSectionsMap[section.id].photoUrl } };
          }
          return filledSectionsMap[section.id];
        } else {
          return { pdfSection: section, pdfSectionId: section.id };
        }
      });

      filledAndUnfilledSections.sort((a, b) =>
        dayjs(a.pdfSection.createdAt).isBefore(dayjs(b.pdfSection.createdAt)) ? -1 : 1,
      );

      setAllSections(filledAndUnfilledSections);
    }
  }, [pdfSections, filledSections, isDefaultTemplate]);

  const getDefaultTemplateValues = (sectionName: string) => {
    switch (sectionName) {
      case 'Background':
        return DEFAULT_VALUES.background;
      case 'Hotel Logo':
        return null;
      case 'Main Photo':
        return null;
      case 'Gift Card Header':
        return DEFAULT_VALUES.giftCardHeader;
      case 'Gift Card Title':
        return { ...DEFAULT_VALUES.giftCardTitle, text: giftCard?.title };
      case 'Gift Card Description':
        return {
          ...DEFAULT_VALUES.giftCardDescription,
          text: giftCard?.description
            .replace(/<\/?p>/g, '')
            .replace(/&nbsp;/g, '')
            .replace(/<br\/?>/g, '\n'),
        };
      case 'Line Above Gift Card Code':
        return DEFAULT_VALUES.lineAboveGiftCardCode;
      case 'Gift Card Code':
        return DEFAULT_VALUES.giftCardCode;
      case 'Expiration Date':
        return DEFAULT_VALUES.expirationDate;
      case 'Line Below Expiration Date':
        return DEFAULT_VALUES.lineBelowExpirationDate;
      case 'Qr Code':
        return DEFAULT_VALUES.qrCode;
      case 'Booking Instructions':
        return DEFAULT_VALUES.bookingInstructions;
      case 'Hotel Information':
        return DEFAULT_VALUES.hotelInformation;
      case 'Box Around QR Code':
        return DEFAULT_VALUES.boxAroundQrCode;
      default:
        return null;
    }
  };

  const formItemLayout = {
    labelCol: { xs: { span: 24 }, sm: { span: 8 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
  };

  const initialValuesPerSection = {
    backgroundType: 0,
    font: null,
    fontSize: null,
    fontColor: null,
    dashSize: null,
    dashSpace: null,
    textAlign: 'left',
    text: null,
    opacity: 1.0,
    paddingTop: 0.0,
    paddingBottom: 0.0,
    paddingLeft: 0.0,
    paddingRight: 0.0,
    cornerRadius: 0.0,
    stretch: false,
    fillColor: '#ffffff',
    visible: true,
  };

  const initialValues = useMemo(() => {
    const sections = allSections.map(section => ({ ...initialValuesPerSection, ...section }));
    return sections;
  }, [allSections, pdfSections, filledSections, isDefaultTemplate]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleSectionFieldChange = (field: [number, string], value: string | number | File | null) => {
    form.setFieldValue(field, value);
  };

  return (
    <div data-testid="GiftCardPdfMultipleForms" className={styles.root}>
      {!allSections ? (
        <Loading />
      ) : (
        <Form name="FormPdfSections" form={form} initialValues={initialValues} {...formItemLayout}>
          {allSections.map((item: IFilledSection, index: number) => (
            <div key={`pdf-settings-${index}`} className={styles.filledSection}>
              <GiftCardPdfForm
                key={index}
                index={index}
                filledSection={item}
                onFieldChange={handleSectionFieldChange}
                isDefaultTemplate={isDefaultTemplate}
              />
            </div>
          ))}
        </Form>
      )}
    </div>
  );
};

export default GiftCardPdfMultipleForms;
