// Generated with util/create-component.js
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Menu, Button, Form, RadioChangeEvent } from 'antd';

import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import notification from 'utils/notification';

import { IGiftCardFormValues, IMultiPropertyRatePlans, IOption } from 'components/gift_card_form/gift_card_form.types';
import { IPhoto, IPropertyUpsellRate } from 'components/gift_card_form/gift_card_form.types';
import { IPropertyUnit, IRatePlan } from 'components/gift_card_list/published_gift_cards/published_gift_cards.types';
import { IPdfTemplate } from 'components/gift_card_pdf_details/gift_card_pdf_details.types';

import GiftCardForm from 'components/gift_card_form';
import GiftCardPdfDetails from 'components/gift_card_pdf_details/gift_card_pdf_details';

import { IGiftCardDetailsProps } from './gift_card_details.types';
import styles from './gift_card_details.module.scss';

const GiftCardDetails: React.FC<IGiftCardDetailsProps> = ({
  giftCard,
  isLoadingRatePlans,
  selectedProperty,
  onDeletePhoto,
  userHotelRoomTypeRatePlans,
  groupStores,
  properties,
  pdfTemplates,
  isLoadingPdfTemplates,
  onLoadPdfTemplates,
  filledSections,
  onSaveChanges,
  isLoadingFilledSections,
  onLoadFilledSections,
}) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState('general');
  const [selectedTemplate, setSelectedTemplate] = useState<number>(
    giftCard?.pdfTemplateId || pdfTemplates?.find((pt: IPdfTemplate) => pt?.default === true)?.id || 3,
  );
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [photos, setPhotos] = useState<IPhoto[]>(giftCard?.photos || []);

  const [allStoresSupportPickup, setAllStoresSupportPickup] = useState<boolean>(false);

  const [pdfForm] = Form.useForm();
  const [allUpsellOptions, setAllUpsellOptions] = useState<IOption[]>([]);
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);

  const [allOptions, setAllOptions] = useState<IOption[]>([]);

  const [form] = Form.useForm();
  const giftcardType = Form.useWatch('giftcardType', form);

  const menuItems = useMemo(
    () => [
      {
        key: 'general',
        label: t('general.general'),
      },
      {
        key: 'pdf',
        label: 'Pdf',
      },
    ],
    [],
  );

  const isDefaultTemplate = useMemo(
    () => pdfTemplates?.find(pt => pt.default)?.id === selectedTemplate,
    [selectedTemplate, pdfTemplates],
  );

  useEffect(() => {
    const newOptions = userHotelRoomTypeRatePlans
      .filter(item => (giftcardType === 0 ? item.id === selectedProperty : true))
      .map(({ id, title, roomTypes }) => ({
        value: id,
        label: title,
        children: roomTypes.map(({ id, title, ratePlans }) => ({
          value: id,
          label: title,
          children: ratePlans.map(({ id, title, occupancy }) => ({
            value: id,
            label: `${title} (${occupancy})`,
          })),
        })),
      }));
    setAllOptions(newOptions);
    setAllUpsellOptions(newOptions);
  }, [giftcardType, userHotelRoomTypeRatePlans]);

  useEffect(() => {
    onLoadPdfTemplates();
  }, []);

  useEffect(() => {
    if (selectedTemplate === null) {
      return;
    }
    if (giftCard) {
      onLoadFilledSections(giftCard.id, selectedTemplate);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    checkAllStoresAllowPickup();
  }, [groupStores]);

  useEffect(() => {
    if (giftCard) {
      form.setFieldsValue({
        ...giftCard,
        ...(giftCard.absoluteExpirationDate ? { absoluteExpirationDate: dayjs(giftCard.absoluteExpirationDate) } : {}),
      });
      if (giftCard.absoluteExpirationDate !== null) {
        form.setFieldValue('expirationType', 1);
      }
      if (giftCard.netPrice === giftCard.price) {
        form.setFieldsValue({ netPrice: '' });
      }
      if (giftCard.multiPropertyId) {
        form.setFieldsValue({ giftcardType: 1 });
        initializeRates();
      } else if (!giftCard.ratePlanIds && giftCard.upsellRatePlans?.length === 0) {
        form.setFieldsValue({ giftcardType: 2 });
      } else {
        form.setFieldsValue({ giftcardType: 0 });
        initializeRates();
      }
      setEditorState(EditorState.createWithContent(stateFromHTML(giftCard.description)));
    }
  }, [giftCard]);

  const checkAllStoresAllowPickup = () => {
    const selectedStoreIds = form.getFieldValue('storeIds');
    const selectedStores = groupStores?.filter(store => selectedStoreIds?.includes(store.id));
    setAllStoresSupportPickup(selectedStores?.every(store => store.allowPickupAtHotel));
  };

  const onGeneralValuesChange = (changedValues: IGiftCardFormValues) => {
    if (changedValues.ratePlans !== undefined) {
      const newOptions = userHotelRoomTypeRatePlans
        .filter(item => (giftcardType === 0 ? item.id === selectedProperty : true))
        .filter(item => changedValues?.ratePlans?.some((rate: string[]) => rate[0] === item.id))
        .map(({ id, title, roomTypes }) => ({
          value: id,
          label: title,
          children: roomTypes
            .filter(item => !changedValues?.ratePlans?.some((rate: string[]) => rate[1] === item.id))
            .filter(item => item.ratePlans.length > 0)
            .map(({ id, title, ratePlans }) => ({
              value: id,
              label: title,
              children: ratePlans.map(({ id, title }) => ({
                value: id,
                label: title,
              })),
            })),
        }));
      setAllUpsellOptions(newOptions);
    }
    if (changedValues?.storeIds !== undefined) {
      checkAllStoresAllowPickup();
    }
  };

  const handleMultiPropertyChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({ giftcardType: e.target.value, ratePlans: null, upsellTable: null });
  };

  const initializeRates = () => {
    const rateResult: string[][] = [];

    if (giftCard?.multiPropertyRatePlans) {
      giftCard.multiPropertyRatePlans.forEach((entry: IMultiPropertyRatePlans) => {
        const property = userHotelRoomTypeRatePlans.find(p => p.id === entry.propertyId);
        if (property) {
          property.roomTypes.forEach(roomType => {
            roomType.ratePlans.forEach(ratePlan => {
              if (entry.ratePlanIds.includes(ratePlan.id)) {
                rateResult.push([property.id, roomType.id, ratePlan.id]);
              }
            });
          });
        }
      });
    } else {
      const property = userHotelRoomTypeRatePlans.find(p => p.id === selectedProperty);
      if (property) {
        property.roomTypes.forEach(roomType => {
          roomType.ratePlans.forEach(ratePlan => {
            if (giftCard?.ratePlanIds.includes(ratePlan.id)) {
              rateResult.push([property.id, roomType.id, ratePlan.id]);
            }
          });
        });
      }
    }
    form.setFieldValue('ratePlans', rateResult);

    if (giftCard?.multiPropertyUpsellRatePlans) {
      giftCard.multiPropertyUpsellRatePlans.forEach(property => {
        property.forEach((upsellRate: IPropertyUpsellRate) => {
          const userProperty = userHotelRoomTypeRatePlans.find(hotel => hotel.id === upsellRate.propertyId);
          const roomType = userProperty?.roomTypes.find(
            (roomType: IPropertyUnit) => roomType.id === upsellRate.roomTypeId,
          );
          const ratePlan = roomType?.ratePlans.find((ratePlan: IRatePlan) => ratePlan.id === upsellRate.ratePlanId);
          const selection = {
            id: upsellRate.id,
            hotel: userProperty?.title,
            roomType: roomType?.title,
            ratePlan: ratePlan?.title,
            price: upsellRate.price,
            propertyId: userProperty?.id,
            roomTypeId: roomType?.id,
            ratePlanId: ratePlan?.id,
          };

          form.setFieldsValue({
            upsellTable: [...(form.getFieldValue('upsellTable') || []), selection],
          });
        });
      });
    } else {
      const property = userHotelRoomTypeRatePlans.find(property => property.id === selectedProperty);
      if (property && giftCard?.upsellRatePlans) {
        giftCard?.upsellRatePlans.forEach((upsellRate: IPropertyUpsellRate) => {
          const roomType = property.roomTypes.find((roomType: IPropertyUnit) => roomType.id === upsellRate.roomTypeId);
          const ratePlan = roomType?.ratePlans.find((ratePlan: IRatePlan) => ratePlan.id === upsellRate.ratePlanId);
          if (ratePlan) {
            const selection = {
              id: upsellRate.id,
              hotel: property?.title,
              roomType: roomType?.title,
              ratePlan: ratePlan?.title,
              price: upsellRate.price,
              propertyId: property?.id,
              roomTypeId: roomType?.id,
              ratePlanId: ratePlan?.id,
            };

            form.setFieldsValue({
              upsellTable: [...(form.getFieldValue('upsellTable') || []), selection],
            });
          }
        });
      }
    }
    const currentRates = form.getFieldsValue(['ratePlans']);
    const newOptions = userHotelRoomTypeRatePlans
      .filter(item => (giftcardType === 0 ? item.id === selectedProperty : true))
      .filter(item => currentRates.ratePlans.some((rate: string[]) => rate[0] === item.id))
      .map(({ id, title, roomTypes }) => ({
        value: id,
        label: title,
        children: roomTypes
          .filter(item => !currentRates.ratePlans.some((rate: string[]) => rate[1] === item.id))
          .filter(item => item.ratePlans.length > 0)
          .map(({ id, title, ratePlans }) => ({
            value: id,
            label: title,
            children: ratePlans.map(({ id, title, occupancy }) => ({
              value: id,
              label: `${title} (${occupancy})`,
            })),
          })),
      }));
    setAllUpsellOptions(newOptions);
  };

  const handleSetEditorState = (description: EditorState) => {
    setEditorState(description);
  };

  const handleSaveChanges = async () => {
    let pdfSections;
    let generalFormValues;
    try {
      await pdfForm.validateFields();
      await form.validateFields();
      const pdfFormSections = await pdfForm.getFieldsValue(true);
      pdfSections = Object.keys(pdfFormSections).map(key => pdfFormSections[key]);

      generalFormValues = form.getFieldsValue(true);
    } catch (error) {
      console.error('Form validation failed:', error);
      return;
    }
    if (!allStoresSupportPickup && generalFormValues?.offerPickupAtHotel) {
      generalFormValues.offerPickupAtHotel = false;
    }
    if (!giftCard?.isDuplicate) {
      generalFormValues.id = giftCard?.id;
    }

    try {
      setIsSavingChanges(true);
      await onSaveChanges(
        {
          ...generalFormValues,
          photos,
          description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        },
        selectedTemplate,
        pdfSections,
      );
      notification.withIcon('success', t('gift_card.pdf_update_success'));
    } catch (error) {
      notification.withIcon('error', t('general.error_message'), t('general.error_description'));
    }
    setIsSavingChanges(false);
  };

  return (
    <div data-testid="GiftCardDetails" className={styles.root}>
      {giftCard && (
        <Menu
          className={styles.menu}
          onClick={e => setSelectedTab(e.key)}
          selectedKeys={[selectedTab]}
          mode="horizontal"
          items={menuItems}
        />
      )}
      {selectedTab === 'general' && (
        <GiftCardForm
          giftCard={giftCard}
          form={form}
          isLoadingRatePlans={isLoadingRatePlans}
          properties={properties}
          onDeletePhoto={onDeletePhoto}
          userHotelRoomTypeRatePlans={userHotelRoomTypeRatePlans}
          groupStores={groupStores}
          onUpdatePhotos={setPhotos}
          photos={photos}
          allStoresSupportPickup={allStoresSupportPickup}
          onSetEditorState={handleSetEditorState}
          editorState={editorState}
          allUpsellOptions={allUpsellOptions}
          allOptions={allOptions}
          onValuesChange={onGeneralValuesChange}
          onMultiPropertyChange={handleMultiPropertyChange}
        />
      )}
      {selectedTab === 'pdf' && (
        <GiftCardPdfDetails
          giftCard={giftCard}
          pdfTemplates={pdfTemplates}
          isDefaultTemplate={isDefaultTemplate}
          onLoadPdfTemplates={onLoadPdfTemplates}
          isLoadingPdfTemplates={isLoadingPdfTemplates}
          filledSections={filledSections}
          isLoadingFilledSections={isLoadingFilledSections}
          selectedTemplate={selectedTemplate}
          onSelectTemplate={setSelectedTemplate}
          form={pdfForm}
        />
      )}
      <div className={styles.submit_section}>
        <Button
          loading={isSavingChanges}
          className={styles.full_width}
          type="primary"
          htmlType="submit"
          size="large"
          onClick={handleSaveChanges}
        >
          {giftCard && !giftCard?.isDuplicate ? t('gift_card.update_type') : 'Create Product'}
        </Button>
      </div>
    </div>
  );
};

export default GiftCardDetails;
