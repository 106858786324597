// Generated with util/create-component.js
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Input, Row, Col, Button, Select } from 'antd';
import type { TimeRangePickerProps } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import dateFormatter from 'utils/date_formatter';

import SearchInput from 'components/search_input';
import DateRangePicker from 'components/date_range_picker/date_range_picker';
import { IDatesRange } from 'components/date_range_picker/date_range_picker.types';
import { IProperty } from 'components/admin/properties/properties_list/properties_list.types';
import { IReseller } from 'components/admin/resellers/resellers_list/resellers_list.types';

import { IBooker, IBookingFilters, IBookingsAdvancedSearchProps } from './bookings_advanced_search.types';
import styles from './bookings_advanced_search.module.scss';

const DEFAULT_FILTERS = [
  'reservationId',
  'reservationName',
  'externalReferenceId',
  'dateFrom',
  'dateTo',
  'reseller',
  'supplier',
  'booker',
  'bookedFrom',
  'bookedTo',
];

const BookingsAdvancedSearch: React.FC<IBookingsAdvancedSearchProps> = ({
  onSearch,
  filters,
  isReseller,
  suppliers,
  bookers,
  resellers,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    setAdvancedFilters(filters);
  }, [filters]);

  const [showAdvancedSearch, toggleAdvancedSearch] = useState<boolean>(false);
  const [advancedFilters, setAdvancedFilters] = useState(filters);
  const [isAdvancedSearchActive, setIsAdvancedSearchActive] = useState<boolean>(false);

  const handleToggleAdvancedSearch = () => {
    toggleAdvancedSearch(!showAdvancedSearch);
    setAdvancedFilters(filters);
  };

  const handleResetFilters = () => {
    const defaultFilters = JSON.parse(JSON.stringify(filters));
    DEFAULT_FILTERS.map(filter => delete defaultFilters[filter]);

    setAdvancedFilters(defaultFilters);
    onSearch(defaultFilters);
    setIsAdvancedSearchActive(false);
    toggleAdvancedSearch(false);
  };

  const handleSearch = () => {
    onSearch(advancedFilters);
    setIsAdvancedSearchActive(true);
    toggleAdvancedSearch(false);
  };

  const handleSearchByTerm = (term: string) => {
    if (term) {
      setAdvancedFilters({ ...advancedFilters, term });
      onSearch({
        ...advancedFilters,
        term,
      });
    } else {
      const allFilters = JSON.parse(JSON.stringify(advancedFilters));
      delete allFilters.term;
      setAdvancedFilters(allFilters);
      onSearch(allFilters);
    }
  };

  const handleFilterChange = (name: keyof IBookingFilters, value: string | number | string[]) => {
    const updatedFilters = JSON.parse(JSON.stringify(advancedFilters));
    if (!value || (['supplier', 'booker', 'reseller'].includes(name) && !(value as string[])?.length)) {
      delete updatedFilters[name];
    } else {
      updatedFilters[name] = value;
    }
    setAdvancedFilters(updatedFilters);
  };

  const handleDatesChange = (dateRange: IDatesRange['dateRange']) => {
    const allFilters = JSON.parse(JSON.stringify(advancedFilters));
    if (!dateRange) {
      delete allFilters.dateFrom;
      delete allFilters.dateTo;
    } else if (dateRange?.[0] && dateRange?.[1]) {
      allFilters.dateFrom = dateFormatter.toApi(dateRange[0]);
      allFilters.dateTo = dateFormatter.toApi(dateRange[1]);
    }

    setAdvancedFilters(allFilters);
  };

  const handleBookedDatesChange = (dateRange: IDatesRange['dateRange']) => {
    const allFilters = JSON.parse(JSON.stringify(advancedFilters));
    if (!dateRange) {
      delete allFilters.bookedFrom;
      delete allFilters.bookedTo;
    } else if (dateRange?.[0] && dateRange?.[1]) {
      allFilters.bookedFrom = dateFormatter.toApi(dateRange[0]);
      allFilters.bookedTo = dateFormatter.toApi(dateRange[1]);
    }

    setAdvancedFilters(allFilters);
  };

  const datePickerRanges: TimeRangePickerProps['presets'] = [
    {
      label: 'Past Month',
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
    { label: 'Next Month', value: [dayjs().add(1, 'month').startOf('month'), dayjs().add(1, 'month').endOf('month')] },
  ];

  const searchPlaceholder = useMemo(
    () => (isReseller ? t('booking.search_by_all_as_reseller') : t('booking.search_by_all_as_supplier')),
    [isReseller],
  );

  return (
    <div data-testid="BookingsAdvancedSearch" className={styles.root}>
      <SearchInput
        placeholder={searchPlaceholder}
        onSearch={handleSearchByTerm}
        addonAfter={
          <div
            onClick={() => {
              toggleAdvancedSearch(!showAdvancedSearch);
            }}
            className={styles.search_input_addon}
          >
            <FilterOutlined className={isAdvancedSearchActive ? styles.active_filter : ''} />
            {t('booking.advanced_search')}
          </div>
        }
      />
      {showAdvancedSearch && (
        <div className={styles.search_input_dropdown} data-testid="AdvancedSearchOptions">
          <Row className={styles.filters_wrapper}>
            {isReseller ? (
              <Col span={11} className={styles.search_input_container}>
                <div className={styles.search_label}>{t('general.supplier')}:</div>
                <Select
                  className={styles.selectContainer}
                  showSearch
                  mode="multiple"
                  placeholder={t('general.supplier')}
                  optionFilterProp="children"
                  filterOption={true}
                  value={advancedFilters.supplier}
                  onChange={value => handleFilterChange('supplier', value)}
                  data-testid="Supplier"
                >
                  {suppliers?.map((supplier: IProperty) => (
                    <Select.Option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            ) : (
              <Col span={11} className={styles.search_input_container}>
                <div className={styles.search_label}>{t('general.reseller')}:</div>
                <Select
                  className={styles.selectContainer}
                  showSearch
                  mode="multiple"
                  placeholder={t('general.reseller')}
                  optionFilterProp="children"
                  filterOption={true}
                  onChange={value => handleFilterChange('reseller', value)}
                  value={advancedFilters.reseller}
                  data-testid="Reseller"
                >
                  {resellers?.map((reseller: IReseller) => (
                    <Select.Option key={reseller.id} value={reseller.id}>
                      {reseller.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            )}
            <Col span={11} offset={2} className={styles.search_input_container}>
              <div className={styles.search_label}>{t('general.reservation_name')}:</div>
              <Input
                placeholder={t('general.reservation_name')}
                onChange={({ target: { value } }) => handleFilterChange('reservationName', value)}
                onPressEnter={handleSearch}
                value={advancedFilters.reservationName}
              />
            </Col>
            <Col span={11} className={styles.search_input_container}>
              <div className={styles.search_label}>{t('bookings_list.external_id_title')}:</div>
              <Input
                placeholder={t('bookings_list.external_id_title')}
                onChange={({ target: { value } }) => handleFilterChange('externalReferenceId', value)}
                onPressEnter={handleSearch}
                value={advancedFilters.externalReferenceId}
              />
            </Col>
            <Col span={11} offset={2} className={styles.search_input_container}>
              <div className={styles.search_label}>{t('bookings_list.reservation_id_title')}:</div>
              <Input
                placeholder={t('bookings_list.reservation_id_title')}
                onChange={({ target: { value } }) => handleFilterChange('reservationId', value)}
                onPressEnter={handleSearch}
                value={advancedFilters.reservationId}
              />
            </Col>
          </Row>
          <Row>
            <Col span={isReseller ? 11 : 24} className={styles.dateRangeContainer}>
              <div className={styles.search_label}>{t('general.checkin_checkout')}:</div>
              <DateRangePicker
                onCalendarChange={handleDatesChange}
                className={styles.range_picker}
                ranges={datePickerRanges}
                placeholder={[t('hotel_page.checkin_placeholder'), t('hotel_page.checkout_placeholder')]}
                defaultValue={[
                  filters.dateFrom ? dayjs(filters.dateFrom) : dayjs(),
                  filters.dateTo ? dayjs(filters.dateTo) : undefined,
                ]}
              />
            </Col>
            {isReseller && (
              <Col span={11} offset={2} className={styles.search_input_container}>
                <div className={styles.search_label}>{t('bookings.booker_name')}:</div>
                <Select
                  className={styles.selectContainer}
                  showSearch
                  mode="multiple"
                  placeholder={t('bookings.booker_name')}
                  optionFilterProp="children"
                  filterOption={true}
                  value={advancedFilters.booker}
                  onChange={value => handleFilterChange('booker', value)}
                  data-testid="Booker"
                >
                  {bookers?.map((booker: IBooker) => (
                    <Select.Option key={booker.id} value={booker.id}>
                      {booker.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={11} className={styles.bookingDateContainer}>
              <div className={styles.search_label}>{t('bookings.booking_date')}:</div>
              <DateRangePicker
                onCalendarChange={handleBookedDatesChange}
                className={styles.range_picker}
                ranges={datePickerRanges}
                placeholder={[t('booking.booked_from'), t('booking.booked_to')]}
                defaultValue={
                  filters.bookedFrom && filters.bookedTo
                    ? [dayjs(filters.bookedFrom), dayjs(filters.bookedTo)]
                    : [undefined, undefined]
                }
              />
            </Col>
          </Row>
          <Row justify="end">
            <Button onClick={handleToggleAdvancedSearch}>{t('general.buttons.cancel')}</Button>
            <Button onClick={handleResetFilters} className={styles.reset_button}>
              {t('link.reset')}
            </Button>
            <Button type="primary" onClick={handleSearch}>
              {t('link.search')}
            </Button>
          </Row>
        </div>
      )}
    </div>
  );
};

export default BookingsAdvancedSearch;
