// Generated with util/create-component.js
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Tooltip, Popconfirm, Typography, notification } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';

import DATE_FORMATTER from 'utils/date_formatter';

import { IApiKey, IApiKeysListProps } from './api_keys_list.types';
import styles from './api_keys_list.module.scss';

const { Link } = Typography;

const COLUMN_WIDTH = 150;

const ApiKeysList: React.FC<IApiKeysListProps> = ({ isLoading, apiKeys, onPageChange, onDisableApiKey }) => {
  const { t } = useTranslation();

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('general.success_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handlePageChange = useCallback(async (currentPagination: TablePaginationConfig) => {
    const { current, pageSize } = currentPagination;
    const pagination = {
      page: current || 1,
      limit: pageSize || 10,
    };

    onPageChange(pagination);
  }, []);

  const handleDisableApiKey = async (id: IApiKey['id']) => {
    try {
      await onDisableApiKey(id);
      openNotificationWithIcon('success');
    } catch (e) {
      openNotificationWithIcon('error');
      return;
    }

    const pagination = {
      page: apiKeys?.meta?.page || 1,
      limit: apiKeys?.meta?.limit || 10,
    };

    onPageChange(pagination);
  };

  const renderStatus = (active: boolean) => {
    if (active) return <div className={styles.activeLabel}>{t('contracts.active')}</div>;
    return <div className={styles.disabledLabel}>{t('general.disabled')}</div>;
  };

  const renderDisableAction = (apiKey: IApiKey) => {
    if (!apiKey.active) return null;

    return (
      <Tooltip title={t('api_key.disable')}>
        <Popconfirm
          title={
            <>
              <p>{t('general.confirm')}</p>
              <p>{t('api_key.are_you_sure_you_want_to_disable')}</p>
            </>
          }
          cancelText={t('link.cancel')}
          okText={t('general.yes')}
          placement="bottomRight"
          onConfirm={() => handleDisableApiKey(apiKey.id)}
        >
          <Link className={styles.actionsTitle}>{t('general.disable')}</Link>
        </Popconfirm>
      </Tooltip>
    );
  };

  const columns = [
    {
      title: t('general.other.name'),
      dataIndex: 'name',
      key: 'name',
      width: COLUMN_WIDTH,
    },
    {
      title: t('general.token'),
      render: (apiKey: IApiKey) => '•'.repeat(10) + apiKey.last_6,
      key: 'last_6',
      width: COLUMN_WIDTH,
    },
    {
      title: t('contracts.status'),
      key: 'active',
      render: (apiKey: IApiKey) => renderStatus(apiKey.active),
      width: COLUMN_WIDTH,
    },
    {
      title: t('general.created_at'),
      key: 'createdAt',
      render: (apiKey: IApiKey) => <div>{DATE_FORMATTER.toUiDateTime(apiKey.createdAt)}</div>,
      width: COLUMN_WIDTH,
    },
    {
      title: <div className={styles.actionsTitle}>{t('general.actions')}</div>,
      key: 'actions',
      render: (apiKey: IApiKey) => renderDisableAction(apiKey),
      width: COLUMN_WIDTH,
    },
  ];

  return (
    <div data-testid="ApiKeysList" className={styles.root}>
      <Table
        loading={isLoading}
        scroll={{ y: window.innerHeight - 300 }}
        columns={columns}
        dataSource={apiKeys?.data}
        pagination={{
          current: apiKeys?.meta?.page,
          total: apiKeys?.meta?.total,
          pageSize: apiKeys?.meta?.limit,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        onChange={handlePageChange}
        rowKey="id"
      />
    </div>
  );
};

export default ApiKeysList;
