// Generated with util/create-component.js
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Radio, Select, Button, notification } from 'antd';

import { AdminPropertiesDataContext, AdminPropertiesActionsContext } from 'containers/data_context';
import countries from 'constants/countries';
import timezones from 'constants/timezones';
import { ACCOMMODATION, VEHICLE } from 'constants/defaults';

import { IPropertyFormProps } from './property_form.types';
import styles from './property_form.module.scss';

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const validateMessages = {
  required: 'required',
};

const initialValues = {
  name: '',
  email: '',
  phone: '',
  country: '',
  state: '',
  city: '',
  zipCode: '',
  address: '',
  timezone: '',
  channexPropertyId: '',
  companyRegistrationNumber: '',
  propertyType: ACCOMMODATION,
};

const PropertyForm: React.FC<IPropertyFormProps> = ({ property, onCancel }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { updateProperty, createProperty } = useContext(AdminPropertiesActionsContext);

  const requiredRules = [{ required: true, message: t('validation_messages.required') }];

  const {
    property: { isUpdating, isCreating },
  } = useContext(AdminPropertiesDataContext);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('general.success_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = async (): Promise<void> => {
    const updatedValues = form.getFieldsValue(true);

    try {
      if (property) {
        await updateProperty(updatedValues);
      } else {
        await createProperty(updatedValues);
      }
      openNotificationWithIcon('success');
      onCancel();
    } catch (e) {
      console.log(e, 'error');
      openNotificationWithIcon('error');
    }
  };

  const propertyTypeOptions = [
    { value: VEHICLE, label: t('admin_page.hotel_form.car') },
    { value: ACCOMMODATION, label: t('admin_page.hotel_form.hotel') },
  ];

  return (
    <div data-testid="PropertyForm" className={styles.root}>
      <Form
        form={form}
        initialValues={property ? property : initialValues}
        onFinish={handleSubmit}
        {...formItemLayout}
        validateMessages={validateMessages}
      >
        <Form.Item label={t('property.name')} name="name" rules={[{ required: true }]}>
          <Input placeholder={t('property.name')} />
        </Form.Item>
        <Form.Item
          label={t('general.other.email')}
          name="email"
          rules={[{ required: true }, { type: 'email', message: t('general.email.valid_message') }]}
        >
          <Input placeholder={t('general.other.email')} data-testid="Email" />
        </Form.Item>
        <Form.Item label={t('gift_card.reply_to_name')} name="replyToName">
          <Input placeholder={t('gift_card.reply_to_name')} data-testid="ReplyToName" />
        </Form.Item>
        <Form.Item
          label={t('gift_card.reply_to_email')}
          name="replyToEmail"
          rules={[{ required: false }, { type: 'email', message: t('general.email.valid_message') }]}
        >
          <Input placeholder={t('gift_card.reply_to_email')} data-testid="ReplyToEmail" />
        </Form.Item>
        <Form.Item label={t('general.property_type')} name="propertyType" rules={requiredRules}>
          <Radio.Group options={propertyTypeOptions} optionType="button" buttonStyle="solid" />
        </Form.Item>
        <Form.Item label={t('property.channex_property_id')} name="channexPropertyId" rules={[{ required: true }]}>
          <Input.Password placeholder={t('property.channex_property_id')} />
        </Form.Item>
        <Form.Item label={t('general.company_registration_number')} name="companyRegistrationNumber">
          <Input placeholder={t('general.company_registration_number')} />
        </Form.Item>
        <Form.Item label={t('general.phone')} name="phone">
          <Input placeholder={t('general.phone')} type="number" />
        </Form.Item>
        <Form.Item label={t('table.address')} name="address">
          <Input placeholder={t('table.address')} />
        </Form.Item>
        <Form.Item label={t('general.country')} name="country">
          <Select
            showSearch
            placeholder={t('general.country')}
            optionFilterProp="label"
            filterOption={true}
            options={countries}
            allowClear
          />
        </Form.Item>
        <Form.Item label={t('general.state')} name="state">
          <Input placeholder={t('general.state')} />
        </Form.Item>
        <Form.Item label={t('general.city')} name="city">
          <Input placeholder={t('general.city')} />
        </Form.Item>
        <Form.Item label={t('general.zip_code')} name="zipCode">
          <Input placeholder={t('general.zip_code')} />
        </Form.Item>
        <Form.Item label={t('general.timezone')} name="timezone">
          <Select
            showSearch
            placeholder={t('general.timezone')}
            optionFilterProp="label"
            options={timezones}
            filterOption={true}
          />
        </Form.Item>

        <div className={styles.footer}>
          <Form.Item>
            <Button type="default" onClick={handleCancel}>
              {t('link.cancel')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={property ? isUpdating : isCreating}
              disabled={property ? isUpdating : isCreating}
              className={styles.save_button}
            >
              {t('link.save_changes')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PropertyForm;
