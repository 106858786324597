import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Input, Drawer, notification } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { encode, decode } from 'js-base64';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';
import formatNumber from 'utils/format_price_locale';
import calculatePercentage from 'utils/calculate_percentage';
import useTrackingCookieConsent from 'hooks/useTrackingCookieConsent';

import { SHARP_CORNERS } from 'constants/store_settings_corner_style';

import GiftCardPhotosGallery from 'components/gift_card_photos_gallery/gift_card_photos_gallery';
import GiftCardStoreCartSummary from 'components/gift_card_store_cart_summary/gift_card_store_cart_summary';
import themeStyles from 'components/gift_card_buy_store_page_layout/gift_card_store_theme.module.scss';

import styles from './gift_card_store_details.module.scss';
import { IGiftCardStoreDetailProps, IGiftCard, ICartItem } from './gift_card_store_details.types';

const GiftCardStoreDetails: React.FC<IGiftCardStoreDetailProps> = ({ giftCard, stock, storeSettings }) => {
  const { t } = useTranslation();

  const { selectedProperty } = useParams<{ selectedProperty: string }>();

  const history = useHistory();

  const [giftCardDetails, setGiftCardDetails] = useState<IGiftCard>(giftCard);
  const [cartItemsList, setCartItemsList] = useState<Array<ICartItem>>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const isTrackingAllowed: boolean = useTrackingCookieConsent();

  useEffect(() => {
    setGiftCardDetails(giftCard);
  }, [giftCard]);

  const addToCart = () => {
    setQuantity(1);
    const cartItems = localStorage.getItem(`cart-items-${selectedProperty}`);

    if (quantity > stock) {
      return notification['error']({
        message: 'An error occurred.',
        description: `There are only ${stock} pieces left over from this gift certificate!`,
      });
    }

    // if something in cart
    if (cartItems) {
      const cart = JSON.parse(decode(cartItems));
      const cartQuantity = cart.items
        .map((item: ICartItem) => item.quantity)
        .reduce((prev: number, curr: number) => prev + curr, 0);

      // if more than 10 items in cart
      if (quantity + cartQuantity > 10) {
        return notification['error']({
          message: 'An error occurred.',
          description: 'You can only buy 10 gift certificates!',
        });
      }

      const cartItemIndex = cart.items.findIndex((data: ICartItem) => data.item.id === giftCard.id);

      // if item already in cart
      if (cartItemIndex >= 0) {
        if (cart.items[cartItemIndex].quantity + quantity > stock) {
          return notification['error']({
            message: 'An error occurred.',
            description: `There are only ${stock} pieces left over from this gift certificate! You already have ${cart.items[cartItemIndex].quantity} in the cart`,
          });
        }
        cart.items[cartItemIndex].quantity += quantity;
        localStorage.setItem(`cart-items-${selectedProperty}`, encode(JSON.stringify(cart)));
        setCartItemsList(cart.items);
      } else {
        const cartItem = { item: giftCard, quantity: quantity };
        const newCart = { items: [...cart.items, cartItem] };
        localStorage.setItem(`cart-items-${selectedProperty}`, encode(JSON.stringify(newCart)));
        setCartItemsList(newCart.items);
      }
    } else {
      const cartItem = { items: [{ item: giftCard, quantity: quantity }] };
      localStorage.setItem(`cart-items-${selectedProperty}`, encode(JSON.stringify(cartItem)));
      setCartItemsList(cartItem.items);
    }
    isTrackingAllowed &&
      window?.gtag('event', 'add_to_cart', {
        currency: 'ISK',
        value: giftCard.netPrice * quantity,
        items: [
          {
            item_id: giftCard.id,
            item_name: giftCard.title,
            item_category: 'Gift Card',
            price: giftCard.netPrice,
            discount: giftCard.price - giftCard.netPrice,
            quantity: quantity,
          },
        ],
      });
    showDrawer();
    const redirectPath = buildPath('', routes.giftCardStoreDetailPage, { selectedProperty, giftCardId: giftCard.id });
    history.push('/');
    history.replace(redirectPath);
  };

  const handlePlusQuantity = () => {
    if (quantity <= 9) {
      setQuantity(quantity + 1);
    }
  };

  const handleMinusQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  // this settimeout causes memory leak it needs to be fixed!
  const showDrawer = () => {
    setDrawerVisible(true);
    setTimeout(() => {
      setDrawerVisible(false);
    }, 5000);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const handleOpenPage = useCallback(
    (route: string) => {
      const params: { selectedProperty: string } = { selectedProperty };
      const redirectPath = buildPath('', route, params);
      history.push(redirectPath);
    },
    [history],
  );

  const discountedPrice = useMemo(() => {
    return cartItemsList.map(item => item.item.netPrice * item.quantity).reduce((prev, curr) => prev + curr, 0);
  }, [cartItemsList]);

  const totalPrice = useMemo(() => {
    return cartItemsList.map(item => item.item.price * item.quantity).reduce((prev, curr) => prev + curr, 0);
  }, [cartItemsList]);

  const footer = (
    <div
      className={classNames(
        styles.bottomContainer,
        themeStyles.root,
        storeSettings?.cornerStyle === SHARP_CORNERS ? '' : themeStyles.roundedTheme,
      )}
    >
      <Row className={styles.bottomRow} justify="space-between" align="middle">
        {totalPrice !== discountedPrice && (
          <Col span={24} className={classNames(styles.totalAmount, 'discountPrice')}>
            {formatNumber(totalPrice)} kr
          </Col>
        )}
        <Col className={classNames(styles.total, themeStyles.highlighted)}>{t('hotel_page.total')}</Col>
        <Col className={themeStyles.highlighted}>
          <div className={styles.summary}>{formatNumber(discountedPrice)} kr</div>
        </Col>
      </Row>

      <div className={styles.cartButtonContainer}>
        <Button
          onClick={() => handleOpenPage(routes.giftCardStoreCheckoutPage)}
          className={classNames(styles.payButton, themeStyles.blackButton, themeStyles.highlighted)}
          htmlType="submit"
          size="large"
        >
          {t('general.pay')}
        </Button>
        <Button
          onClick={() => handleOpenPage(routes.giftCardStoreCartPage)}
          className={classNames(styles.editButton, themeStyles.whiteButton, themeStyles.highlighted)}
          htmlType="submit"
          size="large"
        >
          {t('gift_card.edit_cart')}
        </Button>
      </div>
    </div>
  );

  return (
    <div className={styles.root} data-testid="GiftCardStoreDetails">
      <Row>
        <Col sm={11} xs={24}>
          <GiftCardPhotosGallery photos={giftCardDetails?.photos} />
        </Col>
        <Col sm={13} xs={24} className={styles.textContainer}>
          <Row>
            <Col span={24} className={classNames(styles.giftCardTitle, 'highlighted')} data-testid="GiftCardTitle">
              {giftCardDetails.title}
            </Col>
            <Col span={24} className={styles.giftCardDescription}>
              <div dangerouslySetInnerHTML={{ __html: giftCardDetails.description }}></div>
            </Col>
            <Col span={24} className={styles.giftCardExpiration}>
              {`${t('gift_card.validity_label')}: ${giftCardDetails.expirationDuration} ${
                giftCardDetails.expirationDuration == 1 ? t('general.month') : t('general.months')
              }`}
            </Col>
            <Col span={24} className={classNames(styles.summaryContainer)}>
              <Row className={styles.summaryTitle} justify="space-between" align="middle">
                <Col span={12} className={styles.giftCardSummaryHeader}>
                  {`${giftCardDetails.title} x ${quantity}`}
                </Col>
                <Col span={12}>
                  <Row justify="end" align="middle" gutter={10}>
                    {giftCardDetails.price !== giftCardDetails.netPrice && (
                      <Col className="discountPrice">{`${formatNumber(giftCardDetails.price)} kr`}</Col>
                    )}
                    <Col className="highlighted">{`${formatNumber(giftCardDetails.netPrice)} kr`}</Col>
                  </Row>
                </Col>
              </Row>
              <Row className={classNames(styles.summaryTotal, 'highlighted')} justify="space-between" align="middle">
                {giftCardDetails.price !== giftCardDetails.netPrice && (
                  <Col span={24} className={classNames(styles.discountPrice, 'discountPrice')}>
                    {formatNumber(giftCardDetails.price * quantity)} kr
                  </Col>
                )}
                <Col>{t('hotel_page.total')}</Col>
                <Col>
                  <Row align="middle" gutter={25}>
                    {giftCardDetails.price !== giftCardDetails.netPrice &&
                      calculatePercentage(giftCardDetails.netPrice, giftCardDetails.price) && (
                        <Col className="discountPercentage">
                          {calculatePercentage(giftCardDetails.netPrice, giftCardDetails.price)}
                        </Col>
                      )}
                    <Col className={styles.netPrice}>{formatNumber(giftCardDetails.netPrice * quantity)} kr</Col>
                  </Row>
                </Col>
              </Row>

              <Col span={24} className={classNames(styles.submitSection, 'highlighted')}>
                <Row>
                  <Col className={classNames(styles.quantityButtonWrapper, 'roundedButton')}>
                    <Button
                      onClick={handleMinusQuantity}
                      className={styles.quantityButton}
                      type="default"
                      icon={<MinusOutlined />}
                      size="large"
                    />
                    <Input
                      className={styles.quantityInput}
                      size="large"
                      disabled={true}
                      type="number"
                      placeholder={`${quantity}`}
                    />
                    <Button
                      onClick={handlePlusQuantity}
                      className={styles.quantityButton}
                      type="default"
                      icon={<PlusOutlined />}
                      size="large"
                    />
                  </Col>
                  <Col span={12}>
                    <Button
                      onClick={addToCart}
                      className={classNames('blackButton', styles.submitButton)}
                      htmlType="submit"
                      size="large"
                    >
                      {t('general.add_to_cart')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
      <Drawer
        title={t('gift_card.your_cart')}
        placement="right"
        onClose={onClose}
        open={drawerVisible}
        headerStyle={{
          paddingLeft: 15,
          display: 'flex',
          justifySelf: 'center',
          fontSize: 28,
        }}
        bodyStyle={{ padding: 15, display: 'flex', flexDirection: 'column' }}
        footerStyle={{ borderTop: 'none' }}
        footer={footer}
        rootClassName={themeStyles.root}
      >
        <GiftCardStoreCartSummary storeSettings={storeSettings} cartItems={cartItemsList} />
      </Drawer>
    </div>
  );
};

export default GiftCardStoreDetails;
