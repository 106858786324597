// Generated with util/create-component.js
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Table, Dropdown, Modal, notification, MenuProps, Typography, Drawer, Tooltip, Input } from 'antd';
import { CopyOutlined, DownOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import type { NotificationArgsProps } from 'antd';
import { saveAs } from 'file-saver';

import DRAWER_SIZE from 'constants/drawer_size';
import DATE_FORMATTER from 'utils/date_formatter';
import { BOOKING_STATUS } from 'constants/booking_statuses';
import formatNumber from 'utils/format_price_locale';

import GiftCardDetailsForm from 'components/gift_card_details_form/gift_card_details_form';

import {
  PropertyGiftCardsActionsContext,
  PropertySettingsDataContext,
  BookingsActionsContext,
} from 'containers/data_context';

import { IGiftCardOrdersProps, IGiftCard } from './gift_card_orders.types';
import { IHandleUpdateGiftCardParams } from 'components/gift_card_details_form/gift_card_details_form.types';
import styles from './gift_card_orders.module.scss';
import { IBooking } from 'components/bookings_list/bookings_list.types';
import GiftCardBookingDetails from 'components/gift_card_booking_details/gift_card_booking_details';

type NotificationPlacement = NotificationArgsProps['placement'];
const { Link } = Typography;

const openNotification = (placement: NotificationPlacement, downloadStarted: string, downloadDescription: string) => {
  notification.info({
    message: downloadStarted,
    description: downloadDescription,
    placement,
  });
};

const GiftCardOrders: React.FC<IGiftCardOrdersProps> = ({ giftCardsList, onChange, pagination, isLoading }) => {
  const { t } = useTranslation();

  const { getGiftCardDetailsPdf, getGiftCardDetailsQrCode, putGiftCard } = useContext(PropertyGiftCardsActionsContext);
  const { cancelPropertyBooking } = useContext(BookingsActionsContext);
  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const [ordersList, setOrdersList] = useState<IGiftCard[]>(giftCardsList);
  const [giftCard, setGiftCard] = useState<IGiftCard>();
  const [isDownloadDisabled, setDownloadDisabled] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showMarkAsDeliveredModal, setShowMarkAsDeliveredModal] = useState<boolean>(false);
  const [bookingDetails, setBookingDetails] = useState<IBooking | null>(null);
  const [showDetailsDrawer, setShowDetailsDrawer] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [giftCardNotes, setGiftCardNotes] = useState<string>('');

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setOrdersList(giftCardsList);
  }, [giftCardsList]);

  const closeDetailsDrawer = () => {
    setShowDetailsDrawer(false);
  };

  const handleMarkAsUsedModal = (giftCard: IGiftCard) => {
    setGiftCard(giftCard);
    setShowConfirmationModal(true);
    setGiftCardNotes('');
  };

  const handleMarkAsDeliveredModal = (giftCard: IGiftCard) => {
    setGiftCard(giftCard);
    setShowMarkAsDeliveredModal(true);
  };

  const openDetailsDrawer = (giftCard: IGiftCard) => {
    setGiftCard(giftCard);
    setShowDetailsDrawer(true);
  };

  const handleOkMarkAsUsed = () => {
    giftCard && markGiftCardUsed(giftCard);
    setShowConfirmationModal(false);
  };

  const handleCancelMarkAsUsed = () => {
    setShowConfirmationModal(false);
    setGiftCardNotes('');
  };

  const handleOkMarkAsDelivered = () => {
    giftCard && markGiftCardDelivered(giftCard);
    setShowMarkAsDeliveredModal(false);
  };

  const handleCancelMarkAsDelivered = () => {
    setShowMarkAsDeliveredModal(false);
  };

  const renderMenu = (giftCard: IGiftCard): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        label: t('gift_card.mark_used'),
        key: 'mark_used',
        onClick: () => handleMarkAsUsedModal(giftCard),
        disabled: giftCard.booked,
      },
      { type: 'divider' },
      {
        label: t('gift_card.mark_delivered'),
        key: 'mark_delivered',
        onClick: () => handleMarkAsDeliveredModal(giftCard),
        disabled: giftCard.delivered,
      },
      { type: 'divider' },
      {
        label: t('gift_card.download_pdf'),
        key: 'download_pdf',
        onClick: () => downloadPdf(giftCard),
        disabled: isDownloadDisabled,
      },
      { type: 'divider' },
      {
        label: t('gift_card.download_qr_code'),
        key: 'download_qr_code',
        onClick: () => downloadQrCode(giftCard),
        disabled: isDownloadDisabled,
      },
      { type: 'divider' },
      {
        label: t('gift_card.show_booking'),
        key: 'show_booking',
        onClick: () => showBookingInfo(giftCard),
        disabled: !giftCard.agentBooking,
      },
      {
        label: t('gift_card.details'),
        key: 'show_details',
        onClick: () => openDetailsDrawer(giftCard),
      },
    ];

    return { items: menuItems };
  };

  const handleCancelBooking = async (booking: IBooking) => {
    const { propertyChannelId, reservationId } = booking;

    const cancelledBooking = await cancelPropertyBooking({ selectedProperty, reservationId, propertyChannelId });
    if (cancelledBooking) {
      setBookingDetails(null);
      const index = ordersList.findIndex(giftCard => giftCard?.agentBooking?.reservationId === reservationId);
      const bookingGiftCard = ordersList[index];
      if (bookingGiftCard && bookingGiftCard.agentBooking) {
        bookingGiftCard.agentBooking.status = BOOKING_STATUS.CANCELLED;
        ordersList[index] = bookingGiftCard;
        setOrdersList(ordersList.slice());
      }
    }
  };

  const markGiftCardUsed = async (giftCard: IGiftCard) => {
    const giftCardId = giftCard?.id;
    const data = await putGiftCard({ propertyId: selectedProperty, giftCardId, booked: true, notes: giftCardNotes });
    const index = ordersList.findIndex(order => order.id === giftCardId);

    ordersList[index].booked = data.booked;
    ordersList[index].notes = data.notes;
    setOrdersList(ordersList.slice());
    setGiftCardNotes('');
  };

  const markGiftCardUnused = async (giftCard: IGiftCard) => {
    const giftCardId = giftCard?.id;
    const data = await putGiftCard({ propertyId: selectedProperty, giftCardId, booked: false });
    const index = ordersList.findIndex(order => order.id === giftCardId);

    ordersList[index].booked = data.booked;
    setOrdersList(ordersList.slice());
  };

  const markGiftCardDelivered = async (giftCard: IGiftCard) => {
    try {
      const giftCardId = giftCard?.id;
      const data = await putGiftCard({ propertyId: selectedProperty, giftCardId, delivered: true });
      const index = ordersList.findIndex(order => order.id === giftCardId);
      ordersList[index].delivered = data.delivered;
      setOrdersList(ordersList.slice());
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const handleUpdateGiftCard = async (giftCardParams: IHandleUpdateGiftCardParams) => {
    try {
      const giftCardId = giftCard?.id;
      const data = await putGiftCard({
        propertyId: selectedProperty,
        giftCardId,
        ...giftCardParams,
      });
      const index = ordersList.findIndex(order => order.id === giftCardId);

      ordersList[index].expirationDate = data.expirationDate;
      ordersList[index].notes = data.notes;
      ordersList[index].name = data.name;
      ordersList[index].email = data.email;

      setOrdersList(ordersList.slice());
    } catch (e) {
      console.log('error', e);
    }

    setShowDetailsDrawer(false);
  };

  const downloadPdf = async (giftCard: IGiftCard) => {
    try {
      setDownloadDisabled(true);
      openNotification('bottomRight', t('gift_card.download_started'), t('gift_card.download_description'));
      const pdf = await getGiftCardDetailsPdf({ giftCardId: giftCard.id, propertyId: selectedProperty });
      saveAs(pdf, `gift_card_${giftCard?.title}_${giftCard?.name}`);
    } catch (e) {
      console.log('error', e);
    } finally {
      setDownloadDisabled(false);
    }
  };

  const downloadQrCode = async (giftCard: IGiftCard) => {
    try {
      setDownloadDisabled(true);
      openNotification('bottomRight', t('gift_card.download_started'), t('gift_card.download_qr_description'));
      const qrCode = await getGiftCardDetailsQrCode({ giftCardId: giftCard.id, propertyId: selectedProperty });
      saveAs(qrCode, `gift_card_${giftCard?.generatedId}_qr`);
    } catch (e) {
      console.log('error', e);
    } finally {
      setDownloadDisabled(false);
    }
  };

  const showBookingInfo = async (giftCard: IGiftCard) => {
    if (!giftCard.agentBooking) {
      return;
    }
    try {
      setBookingDetails(giftCard?.agentBooking);
      setGiftCard(giftCard);
    } catch (error) {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const tableScroll = useMemo(() => {
    if (1200 < windowWidth) return { y: window.innerHeight - 310, x: 'max-content' };
    if (770 < windowWidth) return { y: window.innerHeight - 450, x: 'max-content' };
    return { y: window.innerHeight - 510, x: 'max-content' };
  }, [windowWidth]);

  const handleCopy = (value: string) => navigator.clipboard.writeText(value);

  const tableColumns: ColumnsType<IGiftCard> = [
    {
      title: t('gift_card.created_at'),
      dataIndex: 'createdAt',
      render: (createdAt: IGiftCard['createdAt']) => <div>{DATE_FORMATTER.toUi(createdAt)}</div>,
    },
    {
      title: t('gift_card.booked_status'),
      dataIndex: 'booked',
      render: (isBooked: IGiftCard['booked'], giftCard) => (
        <div>
          {isBooked ? (
            giftCard.agentBooking ? (
              <Link onClick={() => showBookingInfo(giftCard)}>{t('gift_card.booked_true')}</Link>
            ) : (
              t('gift_card.booked_true')
            )
          ) : giftCard.agentBooking ? (
            <Link onClick={() => showBookingInfo(giftCard)}>{t('gift_card.booked_false')}</Link>
          ) : (
            t('gift_card.booked_false')
          )}
        </div>
      ),
    },
    {
      title: t('gift_card.code'),
      dataIndex: 'generatedId',
      sorter: (b1: IGiftCard, b2: IGiftCard) => b1.generatedId?.localeCompare(b2.generatedId),
      render: (generatedId: IGiftCard['generatedId']) => (
        <div>
          {generatedId}
          <Tooltip title={t('general.copy')}>
            <CopyOutlined className={styles.copy_option} onClick={() => handleCopy(generatedId || '')} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('gift_card.expiration'),
      dataIndex: 'expirationDate',
      render: (expirationDate: IGiftCard['expirationDate']) => <div>{DATE_FORMATTER.toUi(expirationDate)}</div>,
      sorter: (b1: IGiftCard, b2: IGiftCard) => dayjs(b1.expirationDate).diff(dayjs(b2.expirationDate), 'second'),
    },
    {
      title: t('gift_card.price'),
      dataIndex: 'netPrice',
      render: (netPrice: number) => {
        return `${formatNumber(netPrice)} kr`;
      },
    },
    {
      title: t('gift_card.purchaser'),
      dataIndex: 'buyerName',
      width: '7%',
      render: (_, record) => {
        return <div className={styles.ellipses}>{record.buyerName}</div>;
      },
    },
    {
      title: t('gift_card.purchase.generated_id'),
      dataIndex: 'purchaseGeneratedId',
      width: '7%',
    },
    {
      title: t('gift_card.recipient'),
      dataIndex: 'name',
      width: '7%',
      render: (_, record) => {
        return <div className={styles.ellipses}>{record.name}</div>;
      },
    },
    {
      title: t('gift_card.supplier'),
      dataIndex: 'supplierName',
      render: (_, record) => {
        return <div className={styles.ellipses}>{record.supplierName}</div>;
      },
    },
    {
      title: t('gift_card.source'),
      dataIndex: 'sourceOfSale',
    },
    {
      title: t('gift_card.delivery_status'),
      dataIndex: 'delivered',
      render: (_, record) => {
        return (
          <div className={styles.ellipses}>
            {record.delivered ? t('gift_card.orders_filter_sent') : t('gift_card.orders_filter_not_sent')}
          </div>
        );
      },
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Dropdown menu={renderMenu(record)}>
          <div className={styles.actions_link}>
            <span className={styles.action_link_text}>{t('general.actions')}</span> <DownOutlined />
          </div>
        </Dropdown>
      ),
    },
  ];

  return (
    <div data-testid="GiftCardsOrders" className={styles.root}>
      <Table
        dataSource={ordersList}
        columns={tableColumns}
        rowKey="generatedId"
        pagination={pagination}
        tableLayout="fixed"
        data-testid="GiftCardOrdersList"
        onChange={onChange}
        loading={isLoading}
        scroll={tableScroll}
      />
      <Modal
        transitionName=""
        maskTransitionName=""
        open={showConfirmationModal}
        title={t('gift_card.mark_used_modal_title')}
        onOk={handleOkMarkAsUsed}
        okButtonProps={{ disabled: giftCardNotes.length === 0 }}
        onCancel={handleCancelMarkAsUsed}
      >
        <p>
          {t('gift_card.mark_used_action')} <b> {giftCard?.generatedId} </b> {t('general.as_used')}.
        </p>
        <Input.TextArea
          value={giftCardNotes}
          onChange={e => setGiftCardNotes(e.target.value)}
          status={giftCardNotes.length === 0 ? 'error' : ''}
          placeholder={t('gift_card.notes_placeholder')}
          rows={4}
        />
      </Modal>
      <Modal
        transitionName=""
        maskTransitionName=""
        open={showMarkAsDeliveredModal}
        title="Are you sure?"
        onOk={handleOkMarkAsDelivered}
        onCancel={handleCancelMarkAsDelivered}
      >
        <p>{`You are marking giftcard ${giftCard?.generatedId} as delivered.`}</p>
        <p>Do you want to proceed?</p>
      </Modal>

      <Drawer
        open={!!bookingDetails}
        title={t('booking.details')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
        onClose={() => setBookingDetails(null)}
        destroyOnClose={true}
      >
        {bookingDetails && giftCard && (
          <GiftCardBookingDetails
            booking={bookingDetails}
            giftCard={giftCard}
            isReseller={false}
            onCancelBooking={handleCancelBooking}
            onMarkGiftCard={markGiftCardUnused}
          />
        )}
      </Drawer>
      <Drawer
        destroyOnClose
        title={t('gift_card.details')}
        open={showDetailsDrawer}
        onClose={closeDetailsDrawer}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
      >
        {giftCard && <GiftCardDetailsForm giftCard={giftCard} handleUpdateGiftCard={handleUpdateGiftCard} />}
      </Drawer>
    </div>
  );
};

export default GiftCardOrders;
