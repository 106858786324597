import React from 'react';

import { Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { IGiftCardPurchaseFiltersProps } from './gift_card_purchase_filters.types';
import styles from './gift_card_purchase_filters.module.scss';

const { Option } = Select;

const GiftCardPurchaseFilters: React.FC<IGiftCardPurchaseFiltersProps> = ({
  onDatePickerChange,
  onSelectStatus,
  openDatePicker,
  setOpenDatePicker,
}) => {
  const { t } = useTranslation();

  return (
    <div data-testid="GiftCardPurchaseFilters" className={styles.root}>
      <div className={styles.flexContainer}>
        <DatePicker.RangePicker
          data-testid="DatePicker"
          variant="borderless"
          onChange={onDatePickerChange}
          open={openDatePicker}
          onOpenChange={setOpenDatePicker}
          className={`${styles.flexStatic} ${styles.rangePicker}`}
        />
        <Select
          data-testid="SelectPaymentStatus"
          variant="borderless"
          onChange={onSelectStatus}
          placeholder={t('gift_card.purchase_status')}
          className={`${styles.flexStatic} ${styles.selectPurchaseStatus}`}
        >
          <Option value="all">{t('gift_card.purchase.all')}</Option>
          <Option value="paid">{t('gift_card.purchase.paid')}</Option>
          <Option value="initiated">{t('gift_card.purchase.unpaid')}</Option>
        </Select>
      </div>
    </div>
  );
};

export default GiftCardPurchaseFilters;
