import React, { useContext, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  UnorderedListOutlined,
  LogoutOutlined,
  SettingOutlined,
  ToolOutlined,
  BellOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { ReactComponent as CustomIcon } from 'assets/cookie.svg';
import { Button, Dropdown, Select, MenuProps, Badge } from 'antd';

import buildPath from 'utils/build_path';
import isCarProperty from 'utils/is_car_property';
import routes from 'routing/routes';
import { ReactComponent as Logo } from 'assets/logo.svg';

import USER_ROLE from 'constants/user_role';

import LocaleSelectDesktop from 'components/locale_select_desktop/locale_select_desktop';
import { PropertySettingsDataContext } from 'containers/data_context';

import styles from './nav_desktop.module.scss';
import { IProperty } from 'components/nav/nav.types';
import { IStoreSettings } from 'components/gift_cards_store_settings_form/gift_cards_store_settings_form.types';
import { INavDesktopProps } from './nav_desktop.types';
import { DefaultOptionType } from 'antd/lib/select';

const settingsPages = [
  routes.settingsPagePropertyDetails,
  routes.settingsPageRoomsAndRates,
  routes.settingsPagePolicies,
  routes.settingsPageStores,
  routes.settingsPagePaymentProviders,
  routes.settingsPageAdmin,
  routes.settingsPage,
];

const NavDesktop: React.FC<INavDesktopProps> = ({
  user,
  onSwitchProperty,
  onLogout,
  checkLock,
  checkWarning,
  isReseller,
  stripeRedirect,
  stripeNotification,
}) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  const [isSearchingProperty, toggleSearchingProperty] = useState(false);
  const propertySettingsData = useContext(PropertySettingsDataContext);
  const {
    propertiesList: { data: properties, isLoading },
    selectedProperty,
    propertyGiftCardsStores: { data: giftCardsStores },
  } = propertySettingsData;

  const isActiveLink = (route: string) => !!useRouteMatch(route);
  const carRentalFeatureEnabled = user?.agent?.carRentalFeature;

  let skipRoutes: string[];
  if (isReseller) {
    skipRoutes = [...settingsPages, routes.changePassword, routes.homePage];
  } else {
    skipRoutes = [...settingsPages, routes.changePassword, routes.homePage, routes.bookingsPage];
  }

  const navbarLocked = useMemo(() => {
    return checkLock(selectedProperty);
  }, [selectedProperty]);

  const navbarWarning = useMemo(() => {
    return checkWarning(selectedProperty);
  }, [selectedProperty]);

  const propertyType = useMemo(() => {
    return properties?.find((p: IProperty) => p.value === selectedProperty)?.propertyType;
  }, [selectedProperty]);

  const leftContent = user?.name || '';

  const handleOpenGiftCardStore = useCallback(
    (route: string, params: { selectedProperty: string; slugName: string }) => {
      // replace app.reserva.is with gjafabref.reserva.is
      if (navbarLocked && !skipRoutes.includes(route)) return;
      let currentLocation = window.location.origin;
      currentLocation = currentLocation.replace('app.reserva.is', 'gjafabref.reserva.is');
      const pagePath = buildPath(`?locale=${i18n.language}`, route, { selectedProperty: params.slugName });
      const redirectUrl = `${currentLocation}${pagePath}`;
      window.open(redirectUrl, '_blank');
    },
    [selectedProperty, propertySettingsData, navbarLocked],
  );

  const handleOpenPage = useCallback(
    (route: string, params = {}) => {
      if (navbarLocked && !skipRoutes.includes(route)) return;

      const pagePath = buildPath('', route, params);
      history.push(pagePath);
    },
    [history, navbarLocked],
  );

  const handleOpenCookieConsent = useCallback(() => {
    window.showCookieConsent();
  }, [history]);

  const handleToggleSearchProperty = (propertyId: string) => {
    toggleSearchingProperty(false);
    onSwitchProperty(propertyId);
    if (checkLock(propertyId)) history.push(routes.bookingsPage);
  };

  const handleFilteringProperty = (input: string, option: DefaultOptionType | undefined) => {
    if (!option || !option?.children) return false;

    return option?.children.toString().toLowerCase().includes(input.toLowerCase());
  };

  const navbarLinkStyles = (route: string) => {
    if (isActiveLink(route)) return styles.nav_link_active;
    if (navbarLocked && !skipRoutes.includes(route)) return styles.disabled_nav_link;
    return styles.nav_link;
  };

  const navbarLinkStoresStyles = (route?: string) => {
    if (!route) {
      if (navbarLocked) return styles.disabled_gift_card;
      return styles.gift_card;
    }
    if (navbarLocked && !skipRoutes.includes(route)) return styles.disabled_nav_link;
    return styles.nav_link;
  };

  const renderMenu = (): MenuProps => {
    let menuItems: MenuProps['items'] = [];
    if (!isReseller) {
      menuItems.push({
        key: 'settings',
        label: t('link.settings'),
        icon: <SettingOutlined />,
        onClick: () => handleOpenPage(routes.settingsPagePropertyDetails),
      });
    }

    if (user?.role === USER_ROLE.ADMIN) {
      menuItems.push({
        key: 'admin',
        label: t('link.admin'),
        icon: <SettingOutlined />,
        onClick: () => handleOpenPage(routes.adminPage),
      });
    }

    if (isReseller) {
      menuItems.push({
        key: 'apiKeys',
        label: 'Api Keys',
        icon: <KeyOutlined />,
        onClick: () => handleOpenPage(routes.apiKeysPage),
        disabled: navbarLocked,
      });
    }

    if (user?.role !== USER_ROLE.ADMIN) {
      menuItems.push({
        key: 'billing',
        label: <Badge dot={navbarWarning || navbarLocked || stripeNotification}>{t('auth.billing')}</Badge>,
        icon: <ToolOutlined />,
        onClick: () => stripeRedirect(),
      });
    }

    menuItems = [
      ...menuItems,
      {
        key: 'password',
        label: t('auth.change_password'),
        icon: <ToolOutlined />,
        onClick: () => handleOpenPage(routes.changePassword),
      },
      {
        key: 'cookie_consent',
        label: t('navigation.cookie_consent'),
        icon: <Icon component={CustomIcon} />,
        onClick: handleOpenCookieConsent,
      },
      {
        key: 'logout',
        label: t('navigation.logout'),
        icon: <LogoutOutlined />,
        onClick: onLogout,
      },
    ];
    return { items: menuItems };
  };

  if (!user) return null;

  const createActiveStoreItem = (gs: IStoreSettings) => ({
    key: gs.id,
    label: <div className={styles.menu_item}>{gs.name}</div>,
    onClick: () => handleOpenGiftCardStore(routes.giftCardStorePage, { selectedProperty, slugName: gs.slugName }),
    disabled: navbarLocked,
  });

  const renderStoreNavItems = () => {
    if (isReseller) return null;

    const activeStores = giftCardsStores?.filter((gs: IStoreSettings) => gs.status === 'active') || [];

    if (activeStores.length > 1) {
      const activeStoresItems = activeStores.map(createActiveStoreItem);

      return (
        <Dropdown menu={{ items: activeStoresItems }}>
          <Button className={navbarLinkStoresStyles()} key="Store">
            {t('general.store')}
          </Button>
        </Dropdown>
      );
    } else {
      return (
        <div
          className={navbarLinkStoresStyles(routes.giftCardStorePage)}
          onClick={() =>
            handleOpenGiftCardStore(routes.giftCardStorePage, {
              selectedProperty,
              slugName: giftCardsStores?.[0]?.slugName,
            })
          }
          key={giftCardsStores?.[0]?.id}
        >
          {t('general.store')}
        </div>
      );
    }
  };

  return (
    <nav className={styles.root} data-testid="NavDesktop">
      <div className={styles.logo_container}>
        <div className={styles.logo}>
          <Logo className={styles.logo} onClick={() => handleOpenPage(routes.bookingsPage)} />
        </div>
        {isReseller && carRentalFeatureEnabled && (
          <div
            className={navbarLinkStyles(routes.searchCarsPage)}
            onClick={() => handleOpenPage(routes.searchCarsPage)}
          >
            {t('link.cars')}
          </div>
        )}
        <div className={navbarLinkStyles(routes.bookingsPage)} onClick={() => handleOpenPage(routes.bookingsPage)}>
          <div>{t('link.bookings')}</div>
        </div>
        {!isReseller && (
          <div className={navbarLinkStyles(routes.inventoryPage)} onClick={() => handleOpenPage(routes.inventoryPage)}>
            {t('link.inventory')}
          </div>
        )}
        {!isReseller && (
          <div className={navbarLinkStyles(routes.ordersPage)} onClick={() => handleOpenPage(routes.ordersPage)}>
            {t('general.orders')}
          </div>
        )}
        {!isReseller && (
          <div className={navbarLinkStyles(routes.giftCardPage)} onClick={() => handleOpenPage(routes.giftCardPage)}>
            {t('general.gift_cards')}
          </div>
        )}
        {!isReseller && (
          <div className={navbarLinkStyles(routes.productsPage)} onClick={() => handleOpenPage(routes.productsPage)}>
            {t('general.products')}
          </div>
        )}
        {renderStoreNavItems()}
        {isCarProperty(propertyType) && (
          <div className={navbarLinkStyles(routes.contractsPage)} onClick={() => handleOpenPage(routes.contractsPage)}>
            {t('link.contracts')}
          </div>
        )}
      </div>
      <div className={styles.left_content}>
        {selectedProperty && !isReseller && (
          <div className={isSearchingProperty ? styles.search_property_wrapper : ''}>
            <Select
              showSearch
              style={{ width: isSearchingProperty ? '100%' : '200px' }}
              open={isSearchingProperty}
              autoFocus={isSearchingProperty}
              defaultOpen={isSearchingProperty}
              placeholder={t('general.property_title')}
              loading={isLoading}
              defaultValue={selectedProperty}
              optionFilterProp="children"
              filterOption={(input, option) => handleFilteringProperty(input, option)}
              onDropdownVisibleChange={toggleSearchingProperty}
              onFocus={() => toggleSearchingProperty(true)}
              onSelect={() => toggleSearchingProperty(false)}
              popupMatchSelectWidth={true}
              onChange={handleToggleSearchProperty}
            >
              {properties?.map((property: IProperty, index: number) => (
                <Select.Option value={property.value} key={`${property}-${index}`}>
                  {property.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        <Dropdown menu={renderMenu()}>
          <Button className={styles.button}>
            {leftContent} <UnorderedListOutlined />
          </Button>
        </Dropdown>
      </div>
      <div className={styles.notification_container}>
        <BellOutlined className={styles.notification} id="beamer-newsfeed-button" />
      </div>
      <div className={styles.nav_language}>
        <LocaleSelectDesktop />
      </div>
    </nav>
  );
};

export default NavDesktop;
