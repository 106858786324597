// Generated with util/create-component.js
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadFile } from 'antd/lib/upload';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Select,
  Button,
  Radio,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  Upload,
  Modal,
  InputNumber,
  Checkbox,
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RadioChangeEvent } from 'antd/lib/radio';

import FONTS from 'constants/fonts';
import { ROUNDED_CORNERS, SHARP_CORNERS } from 'constants/store_settings_corner_style';

import { IGiftCardsStoreSettingsFormProps, IPhoto } from './gift_cards_store_settings_form.types';
import styles from './gift_cards_store_settings_form.module.scss';
import _ from 'lodash';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const GiftCardsStoreSettingsForm: React.FC<IGiftCardsStoreSettingsFormProps> = ({
  storeSettings,
  isUpdatingStoreSettings,
  onUpdateStoreSettings,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isSlugModalVisible, setIsSlugModalVisible] = useState(false);
  const [isDayDeadlineDisabled, setIsDayDeadlineDisabled] = useState(!storeSettings.allowCancellation);
  const [isPickupAtHotelEnabled, setIsPickupAtHotelEnabled] = useState(storeSettings.allowPickupAtHotel);
  const [status, setStatus] = useState(storeSettings.status || 'active');

  const fonts = useMemo(() => {
    return FONTS.map((font: string) => ({ value: font, label: <div style={{ fontFamily: font }}>{font}</div> }));
  }, [FONTS]);

  useEffect(() => {
    form.resetFields();
  }, [storeSettings]);

  const handlePhotoRemove = () => {
    form.setFieldsValue({ photo: null });
  };

  const handleLogoRemove = () => {
    form.setFieldsValue({ logo: null });
  };

  const handleStatusChange = (e: CheckboxChangeEvent) => {
    setStatus(e.target.checked ? 'active' : 'inactive');
    form.setFieldsValue({ status: e.target.checked ? 'active' : 'inactive' });
  };

  const handlePickupAtHotelChange = (e: RadioChangeEvent) => {
    setIsPickupAtHotelEnabled(e.target.value);
    form.setFieldsValue({ allowPickupAtHotel: e.target.value });
  };

  const initialSlug: string = storeSettings.slugName;

  const renderPhoto = (photo: IPhoto) => {
    return (
      <div
        data-testid="GiftCardPhoto"
        className={styles.giftCardPhotoContainer}
        onDragOver={event => event.preventDefault()}
      >
        <Tooltip title={t('photo.remove_photo_title')}>
          <Popconfirm
            title={
              <>
                <p>{t('general.confirm')}</p>
                <p>{t('photo.remove_photo')}</p>
              </>
            }
            cancelText={t('link.cancel')}
            okText={t('general.yes')}
            placement="bottomRight"
            onConfirm={handlePhotoRemove}
          >
            <div className={styles.deletePhotoButton}>
              <DeleteOutlined />
            </div>
          </Popconfirm>
        </Tooltip>
        <img src={photo.url} className={styles.giftCardPhoto} />
      </div>
    );
  };

  const renderLogo = (logo: IPhoto) => {
    return (
      <div
        data-testid="GiftCardLogo"
        className={styles.giftCardPhotoContainer}
        onDragOver={event => event.preventDefault()}
      >
        <Tooltip title={t('photo.remove_photo_title')}>
          <Popconfirm
            title={
              <>
                <p>{t('general.confirm')}</p>
                <p>{t('photo.remove_photo')}</p>
              </>
            }
            cancelText={t('link.cancel')}
            okText={t('general.yes')}
            placement="bottomRight"
            onConfirm={handleLogoRemove}
          >
            <div className={styles.deletePhotoButton}>
              <DeleteOutlined />
            </div>
          </Popconfirm>
        </Tooltip>
        <img src={logo.url} className={styles.giftCardPhoto} />
      </div>
    );
  };

  const getFile = (e: { fileList: UploadFile<IPhoto>[] }) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList[0];
  };

  const handleCancel = () => {
    form.resetFields();
  };

  const onFinish = () => {
    if (form.getFieldValue('slugName') === initialSlug || !storeSettings?.id) {
      handleSubmit();
    } else {
      setIsSlugModalVisible(true);
    }
  };

  const handleCancelModal = () => {
    setIsSlugModalVisible(false);
  };

  const handleSubmit = async (): Promise<void> => {
    const updatedSettings = form.getFieldsValue(true);

    const formData = new FormData();

    Object.keys(updatedSettings).map(key => {
      if (key === 'photo') {
        if (updatedSettings[key]?.file) {
          formData.append(
            `store_params[store_setting_attributes][${key.replace(
              /[A-Z]/g,
              (char: string) => `_${char.toLowerCase()}`,
            )}]`,
            updatedSettings[key].file,
          );
        }
      } else if (key === 'logo') {
        if (updatedSettings[key]?.file) {
          formData.append(
            `store_params[store_setting_attributes][${key.replace(
              /[A-Z]/g,
              (char: string) => `_${char.toLowerCase()}`,
            )}]`,
            updatedSettings[key].file,
          );
        }
      } else if (key === 'slugName' || key === 'name' || key === 'status') {
        formData.append(
          `store_params[${key.replace(/[A-Z]/g, (char: string) => `_${char.toLowerCase()}`)}]`,
          updatedSettings[key],
        );
      } else {
        formData.append(
          `store_params[store_setting_attributes][${key.replace(
            /[A-Z]/g,
            (char: string) => `_${char.toLowerCase()}`,
          )}]`,
          updatedSettings[key],
        );
      }
    });
    await onUpdateStoreSettings(formData);
    setIsSlugModalVisible(false);
  };

  const debouncedOnFinish = useCallback(_.debounce(onFinish, 500), []);

  const validateMessages = {
    required: t('validation_messages.required'),
  };

  const requiredRules = [{ required: true }];

  const cornerOptions = [
    { value: ROUNDED_CORNERS, label: t('general.rounded') },
    { value: SHARP_CORNERS, label: t('general.sharp') },
  ];

  const cancellationOptions = [
    { value: false, label: t('general.no') },
    { value: true, label: t('general.yes') },
  ];

  return (
    <div data-testid="GiftCardsStoreSettingsForm" className={styles.root}>
      <Form
        form={form}
        {...formItemLayout}
        initialValues={storeSettings}
        onFinish={debouncedOnFinish}
        validateMessages={validateMessages}
      >
        <legend>{t('gift_card.store_settings')}</legend>
        <Form.Item label={t('gift_card.store_name')} name="name" rules={requiredRules}>
          <Input placeholder={t('gift_card.store_name')} data-testid="StoreName" />
        </Form.Item>
        <Form.Item label={t('general.title_font')} rules={requiredRules} name="titleFont">
          <Select
            showSearch
            optionFilterProp="value"
            filterOption={true}
            placeholder={t('general.title_font')}
            options={fonts}
          />
        </Form.Item>
        <Form.Item label={t('general.text_font')} rules={requiredRules} name="textFont">
          <Select
            showSearch
            optionFilterProp="value"
            filterOption={true}
            placeholder={t('general.text_font')}
            options={fonts}
          />
        </Form.Item>
        <Form.Item name="cornerStyle" rules={requiredRules} label={t('general.corner_style')}>
          <Radio.Group options={cornerOptions} optionType="button" buttonStyle="outline" data-testid="CornerOptions" />
        </Form.Item>

        <Form.Item
          label={t('general.tag_color')}
          name="tagColor"
          rules={requiredRules}
          className={styles.tagColorContainer}
        >
          <Input placeholder={t('general.tag_color')} type="color" className={styles.tagColor} />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.logo !== currentValues.logo}>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item name="logo" label={t('general.logo')} getValueFromEvent={getFile}>
              <>
                <Upload
                  beforeUpload={() => false}
                  itemRender={(_originNode, file) => renderLogo(file)}
                  accept={'.png,.jpeg,.jpg'}
                  multiple={false}
                  listType="picture-card"
                  maxCount={1}
                  fileList={getFieldValue('logo') ? [getFieldValue('logo')] : []}
                  onChange={({ file }) =>
                    setFieldsValue({ logo: { ...getFieldValue('logo'), file, url: URL.createObjectURL(file) } })
                  }
                >
                  {!getFieldValue('logo') && (
                    <div className={styles.addNewPhoto}>
                      <PlusCircleOutlined className={styles.addPhoto} />
                      <div>{t('photo.add_new')}</div>
                    </div>
                  )}
                </Upload>
              </>
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item label={t('gift_card.payment_terms_and_conditions')} name="termsAndConditions" rules={requiredRules}>
          <TextArea rows={10} placeholder={t('gift_card.payment_terms_and_conditions')} />
        </Form.Item>

        <Form.Item
          name="slugName"
          label={t('gift_card.slug_name')}
          rules={[
            ...requiredRules,
            {
              pattern: /^[a-zA-Z0-9-]+$/,
              message: t('gift_card.slug_validation_error'),
            },
          ]}
        >
          <Input addonBefore={t('gift_card.store_route')} data-testid="SlugName" />
        </Form.Item>
        <Form.Item label={t('gift_card.ga_tracking_id')} name="gaTrackingId">
          <Input placeholder={t('gift_card.ga_tracking_id')} data-testid="GATrackingID" />
        </Form.Item>
        <Form.Item
          label={t('gift_card.purchase_confirmation_email')}
          name="purchaseConfirmationEmail"
          rules={[{ type: 'email', message: t('general.please_enter_valid_email') }]}
        >
          <Input placeholder={t('gift_card.purchase_confirmation_email')} />
        </Form.Item>
        <Form.Item label={t('gift_card.allow_pick_up_at_hotel')} name="allowPickupAtHotel" rules={requiredRules}>
          <Radio.Group
            options={cancellationOptions}
            onChange={handlePickupAtHotelChange}
            optionType="button"
            buttonStyle="outline"
            data-testid="AllowPickupAtHotel"
          />
        </Form.Item>

        <Form.Item
          label={t('gift_card.allow_pick_up_at_hotel_info')}
          tooltip={t('gift_card.allow_pick_up_at_hotel_info_tooltip')}
          name="allowPickupAtHotelInfo"
          rules={[{ required: isPickupAtHotelEnabled }]}
        >
          <TextArea rows={3} placeholder={''} disabled={!isPickupAtHotelEnabled} data-testid="AllowPickupAtHotelInfo" />
        </Form.Item>

        <Form.Item label={t('contracts.status')}>
          <Checkbox checked={status === 'active'} onChange={handleStatusChange} data-testid="Status">
            {status === 'active' ? t('contracts.active') : t('contracts.inactive')}
          </Checkbox>
        </Form.Item>

        <legend>{t('gift_card.store_banner')}</legend>
        <Form.Item label={t('general.banner_title')} name="bannerTitle" rules={requiredRules}>
          <Input placeholder={t('general.banner_title')} />
        </Form.Item>
        <Form.Item label={t('general.banner_paragraph')} name="bannerParagraph" rules={requiredRules}>
          <Input placeholder={t('general.banner_paragraph')} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.photo !== currentValues.photo}>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item name="photo" label={t('general.banner_photo')} getValueFromEvent={getFile}>
              <>
                <Upload
                  beforeUpload={() => false}
                  itemRender={(_originNode, file) => renderPhoto(file)}
                  accept={'.png,.jpeg,.jpg'}
                  multiple={false}
                  listType="picture-card"
                  maxCount={1}
                  fileList={getFieldValue('photo') ? [getFieldValue('photo')] : []}
                  onChange={({ file }) =>
                    setFieldsValue({ photo: { ...getFieldValue('photo'), file, url: URL.createObjectURL(file) } })
                  }
                >
                  {!getFieldValue('photo') && (
                    <div className={styles.addNewPhoto}>
                      <PlusCircleOutlined className={styles.addPhoto} />
                      <div>{t('photo.add_new')}</div>
                    </div>
                  )}
                </Upload>
              </>
            </Form.Item>
          )}
        </Form.Item>
        <legend>{t('gift_card.booking_confirmation')}</legend>
        <Form.Item label={t('gift_card.booking_terms_and_conditions')} name="bookingTermsAndConditions">
          <TextArea rows={10} placeholder={t('gift_card.booking_terms_and_conditions')} />
        </Form.Item>

        <Form.Item name="allowCancellation" rules={requiredRules} label={t('gift_card.allow_cancel_booking')}>
          <Radio.Group
            options={cancellationOptions}
            onChange={e => setIsDayDeadlineDisabled(!e.target.value)}
            optionType="button"
            buttonStyle="outline"
            data-testid="CancellationOptions"
          />
        </Form.Item>
        <Form.Item
          name="cancellationDeadlineDays"
          rules={[
            {
              type: 'number',
              min: 0,
              required: !isDayDeadlineDisabled,
              message: t('general.enter_valid_cancellation'),
            },
          ]}
          label={t('gift_card.cancellation_deadline_days')}
        >
          <InputNumber controls={false} placeholder={t('general.days')} disabled={isDayDeadlineDisabled} />
        </Form.Item>

        <Row gutter={24} justify="end" className={styles.controlsRow}>
          <Col>
            <Form.Item>
              <Button type="default" onClick={handleCancel} disabled={isUpdatingStoreSettings}>
                {t('link.cancel')}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isUpdatingStoreSettings}
                disabled={isUpdatingStoreSettings}
              >
                {t('link.save_changes')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        title={t('gift_card.slug_modal_title')}
        open={isSlugModalVisible}
        onOk={handleSubmit}
        onCancel={handleCancelModal}
      >
        <p>{t('gift_card.slug_modal_description')}</p>
      </Modal>
    </div>
  );
};

export default GiftCardsStoreSettingsForm;
